import { DonationIconOne } from "../../data/Assets";

export default function TotalPlatformDonation({ amount }) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center ">
          <img className="cardIcon" src={DonationIconOne} alt="Donation" />
          <div className="ml-1">
            <h3 className="cardTitle">
              {amount ? amount.toLocaleString() + " " + "Rs" : "0 Rs"}
            </h3>
            <p className="widget-text">Collected Donations</p>
          </div>
        </div>
      </div>
    </div>
  );
}
