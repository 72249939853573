import React, {useEffect, useState} from "react";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";
import {getSupporter} from "../Api-Calls/supporter";
import SupporterDatatable from "../component/SupporterDatatable";
const Supporters = () => {
  const [supporter, setSupporter] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  

  useEffect(() => {
    getSupporterData();
  }, []);
  const getSupporterData = async () => {
    const res = await getSupporter();
    try {
      if (res?.status == 200) {
        const supportersList = res?.data?.result;
        setSupporter(supportersList);
        setFilteredList(supportersList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
    },
    {
      title: " Username",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Fundraiser Title",
      dataIndex: "title",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
    },
    {
      title: "Donation Amount",
      dataIndex: "donationAmount",
    },
    {
      title: "Platform Fee",
      dataIndex: "platformFee",
    },
    {
      title: "Paymob Fee",
      dataIndex: "paymobFee",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  const handleFilter = (e) => {
    const value = e.target.value;
    if (value !== "-1") {
      const filteredData = supporter?.filter((item) => {
        return value === "-1" || item.payment_status === value;
      });
      setFilteredList(filteredData);
    } else {
      setFilteredList(supporter);
    }
  };

  const handleResetFilters = () => {
    setFilteredList(supporter);
  };
  const handleSearch = async (e) => {
    let value = e.target.value.toLowerCase();
    if (value.length >= 3) {
      const filteredData = supporter?.filter((item) => {
        return (
          item?.personalInfo?.username?.toLowerCase()?.includes(value) ||
          item?.seqId.includes(value.toUpperCase())
        );
      });
      setFilteredList(filteredData);
    } else {
      // If the input has less than 3 characters, reset to the original data
      setFilteredList(supporter);
    }
  };
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran
              page="All Supporters"
              desc="supporters"
              supporter="supporters"
            />
            <div className="page-inner">
              <div className="row">
                  <div className="ml-3 mb-3 d-flex w-100 justify-content-between align-items-center">
                    <div class="input-group w-25">
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e)}
                        aria-label="Search Something..."
                        aria-describedby="basic-addon2"
                      />
                      <span class="input-group-text border-0" id="search-addon">
                        <i class="fa fa-search"></i>
                      </span>
                    </div>

                    <div className="mr-3 d-flex">
                      <button
                        className="action-button-view  mr-2"
                        onClick={handleResetFilters}
                      >
                        reset
                      </button>
                      <select
                        className="form-control "
                        style={{width: "200px"}}
                        onChange={(e) => handleFilter(e)}
                      >
                         <option value="-1">Select Status</option>
                      <option value="Pending">Declined Payments</option>
                      <option value="Verified">Verified Payments</option>
                      </select>
                    </div>
                  </div>
              </div>
              <SupporterDatatable
                columns={columns}
                supporter={filteredList}
                setSupporter={setSupporter}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Supporters;
