import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
export default function BarChartComp({monthlyDonationData}) {
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    const data = {
        labels: [
            "January",
            "Febaurary",
            "March",
            "April",
            "May",
            "June",
            "July",
            "Augest",
            "September",
            "October",
            "November",
            "December",
        ],
        datasets: [
            {
                label: "Donations Amount PKR",
                // data: [20, 12, 19, 3, 5, 2, 3, 4, 3, 5, 6, 7, 3],
                data: monthlyDonationData,
                backgroundColor: [
                    "#002866",
                    "#002866",
                    "#002866",
                    "#002866",
                    "#002866",
                    "#002866",
                    "#002866",
                ],
                borderColor: [
                    "#002866",
                ],
                borderWidth: 1,
            
            },
        ],
    };
    return (
        <div className="col-md-12">
            <div className="card full-height">
                <div className="card-body">
                    <div className="cardTitleBlue">Collected Amount</div>
                    <div className="row py-3">
                        <div className="col-md-12">
                            <Bar height={50} width={200} data={data} options={options} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
