import "./App.css";
import "./assets/css/atlantis.css";
import "./assets/css/demo.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import Home from "./pages/home";
import Register from "./pages/register";
import ProfilePage from "./pages/ProfilePage";
import Setting from "./pages/setting";
import AddFundraise from "./pages/AddFundraise";
import Supporters from "./pages/supporters";
import NotFound from "./pages/404";
import Fundraiser from "./pages/fundraiser";
import CampaignStoryList from "./pages/campaignStoryList";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import Transaction from "./pages/Transaction";
import UserTransaction from "./pages/UserTransactions";
import UserFundraiser from "./pages/UserFundraiser";
import Contact from "./pages/Contact";
import PrivateRoute from "./privateRoute";
import ForgotPassword from "./pages/forgotPassword";
import Category from "./component/Category";
import BlogForm from "./component/blogs/BlogForm";
import BlogList from "./component/blogs/BlogList";
import StoryList from "./component/successStories/StoryList";
import StoryForm from "./component/successStories/StoryForm";
import Subscriber from "./pages/Subscribers";
import AdminUsers from "./pages/adminUsers";
import Payouts from "./pages/payouts";
import EditCampaignStory from "./pages/EditCampaignStory";
import Volunteer from "./pages/Volunteer";
import CompletedFundraiser from "./pages/completedFundraiser";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/register"
            element={
              <PrivateRoute>
                <Register />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/profile/:id"
            element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/setting"
            element={
              <PrivateRoute>
                <Setting />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/forgot-password/:user_id/:token"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/category"
            element={
              <PrivateRoute>
                <Category />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-fundraise"
            element={
              <PrivateRoute>
                <AddFundraise />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-fundraise/:id"
            element={
              <PrivateRoute>
                <AddFundraise />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-success-story/:id"
            element={
              <PrivateRoute>
                <EditCampaignStory />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fundraiser"
            element={
              <PrivateRoute>
                <Fundraiser />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/completed-fundraiser"
            element={
              <PrivateRoute>
                <CompletedFundraiser />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fundraiser/:id"
            element={
              <PrivateRoute>
                <Fundraiser />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admin-users"
            element={
              <PrivateRoute>
                <AdminUsers />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-user"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-user"
            element={
              <PrivateRoute>
                <AddUser page="edit" />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/supporters/:id"
            element={
              <PrivateRoute>
                <Supporters />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/supporters/"
            element={
              <PrivateRoute>
                <Supporters />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/payouts/"
            element={
              <PrivateRoute>
                <Payouts />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/transaction/:id"
            element={
              <PrivateRoute>
                <Transaction />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/user-transaction/:id"
            element={
              <PrivateRoute>
                <UserTransaction />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/user-fundraiser/:id"
            element={
              <PrivateRoute>
                <UserFundraiser/>
              </PrivateRoute>
            }
          />
           <Route
            exact
            path="/blog-form"
            element={
              <PrivateRoute>
                <BlogForm />
              </PrivateRoute>
            }
          />
              <Route
            exact
            path="/blogs"
            element={
              <PrivateRoute>
                <BlogList />
              </PrivateRoute>
            }
          />
             {/* <Route
            exact
            path="/success-stories"
            element={
              <PrivateRoute>
                <StoryList />
              </PrivateRoute>
            }
          /> */}
             <Route
            exact
            path="/success-stories"
            element={
              <PrivateRoute>
                <CampaignStoryList />
              </PrivateRoute>
            }
          />
             <Route
            exact
            path="/story-form"
            element={
              <PrivateRoute>
                <StoryForm />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/contact-us"
            element={
              <PrivateRoute>
                <Contact />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/volunteer"
            element={
              <PrivateRoute>
                <Volunteer />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/subscribers"
            element={
              <PrivateRoute>
                <Subscriber />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/contact-us/:id"
            element={
              <PrivateRoute>
                <Contact />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
