import React, {useState} from "react";
import "antd/dist/antd.css";
import {Table} from "antd";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Button, Modal} from "react-bootstrap";
import moment from "moment";
import {SwalErrorMessage, SwalMessage} from "./sweetAlertMessage";
import userPicture from "../assets/img/userDefaultImg.png";
import {
  getCompletedFundrasier,
  updateFundraiserWithdrawStatus,
} from "../Api-Calls/fundraiser";
import UserNameLength from "./UserNameLength";

const PayoutDatatable = (props) => {
  const {completeFundraiser, setCompleteFundraiser, columns} = props;
  const [fundraiserDetail, setFundraiserDetail] = useState();
  const [tagModal, setTagModal] = useState(false);
  const [file, setFile] = useState({fileUrl: ""});
  const [error, setError] = useState({image: "", paymentStatus: ""});
  const [loading, setLoading] = useState(false);
  const showTagModal = (e, details) => {
    setFundraiserDetail(details);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };

  const data = [];
  for (let i = 0; i < completeFundraiser?.length; i++) {
    data.push({
      fundraiserId: completeFundraiser[i]?.seqId,
      username: completeFundraiser[i]?.userId?.username
        ? completeFundraiser[i]?.userId?.username
        : completeFundraiser[i]?.adminUserId?.username,
      email: completeFundraiser[i]?.userId?.email
        ? completeFundraiser[i]?.userId?.email
        : completeFundraiser[i]?.adminUserId?.email,
      title: completeFundraiser[i]?.title,
      fundraiser_status: completeFundraiser[i]?.status,
      Withdraw_status: (
        <p
          className={`${
            completeFundraiser[i]?.withdraw_status === "In progress"
              ? "btn btn-sm btn-outline-warning font-weight-bold"
              : "btn btn-sm btn-outline-success font-weight-bold"
          }`}
        >
          {completeFundraiser[i]?.withdraw_status}
        </p>
      ),
      date: moment(completeFundraiser[i]?.updated).format("L"),
      proof_of_payment: completeFundraiser[i]?.picture ? (
        <>
          {" "}
          <img
            src={completeFundraiser[i]?.picture}
            id={`full-screenVideo5-${i}`}
            onClick={(e) => ShowModalPreview(`full-screenVideo5-${i}`)}
            style={{
              height: "40px",
              width: "40px",
              cursor: "pointer",
            }}
            alt="proofOfPaymentImage"
          />
        </>
      ) : (
        "--"
      ),
      Withdraw_Amount: <>Rs. {completeFundraiser[i]?.withdrawAmount}</>,

      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, completeFundraiser[i])}
            className="action-button-view"
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
        </>
      ),
    });
  }

  const getFundraiserData = async () => {
    const res = await getCompletedFundrasier();
    try {
      if (res?.status == 200) {
        setCompleteFundraiser(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [withdrawStatus, setWithdrawStaus] = useState("");
  const handleChange = (e) => {
    setWithdrawStaus(e.target.value);
  };
  const handleUpdate = () => {
    if (file?.fileUrl === "" || file?.fileUrl === undefined) {
      setError((prevError) => ({
        ...prevError,
        image: "Image is required!",
      }));
    }

    if (withdrawStatus === "" || withdrawStatus === undefined) {
      setError((prevError) => ({
        ...prevError,
        paymentStatus: "Please select payment status",
      }));
    }
    const formData = new FormData();
    Array.from(file?.files)?.forEach((item) => {
      formData?.append("files", item);
    });
    formData?.append("withdraw_status", withdrawStatus);
    setLoading(true);
    updateFundraiserWithdrawStatus(fundraiserDetail?._id, formData)
      .then((res) => {
        SwalMessage(res?.data?.status, res?.data?.message, "success");

        getFundraiserData();
        handleClose();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      });
  };
  const ShowModalPreview = (event) => {
    var el = document.getElementById(event);
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };
  return (
    <>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} />

      <Modal show={tagModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Single Transaction Detail</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Fundraiser Id:</strong>
              </div>
              <div className="mx-2 flex2 text-capitalize">
                {fundraiserDetail?.seqId}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Fundraiser Title:</strong>
              </div>
              <div className="mx-2 flex2">{fundraiserDetail?.title}</div>
            </div>
            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Fundraiser Status:</strong>
              </div>
              <div className="mx-2 flex2">{fundraiserDetail?.status}</div>
            </div>
            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Username:</strong>
              </div>
              <div className="mx-2 flex2 text-capitalize">
                <UserNameLength
                  username={fundraiserDetail?.userId?.username}
                  length={30}
                />
              </div>
            </div>

            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Email:</strong>
              </div>
              <div className="mx-2 flex2">
                {fundraiserDetail?.userId?.email}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Profile Image:</strong>
              </div>
              <div className="mx-2 flex2">
                <img
                  src={
                    fundraiserDetail?.userId?.picture
                      ? fundraiserDetail?.userId?.picture
                      : userPicture
                  }
                  id="full-screenVideo"
                  onClick={(e) => ShowModalPreview("full-screenVideo")}
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover",
                  }}
                  alt="profileImage"
                />
              </div>
            </div>

            <div className="d-flex mb-4">
              <div className="mb-1 flex1">
                <strong>Withdraw Amount:</strong>
              </div>
              <div className="mx-2 flex2">
                Rs. {fundraiserDetail?.withdrawAmount}
              </div>
            </div>
            {fundraiserDetail?.withdraw_status == "Verified" && (
              <div className="d-flex mb-4">
                <div className="mb-1 flex1">
                  <strong>Withdraw Status:</strong>
                </div>
                <div className="mx-2 flex2">
                  {fundraiserDetail?.withdraw_status}
                </div>
              </div>
            )}
            {fundraiserDetail?.withdraw_status == "In progress" && (
              <div className="d-flex mb-4">
                <div className="mb-1 flex1">
                  <strong>Payment Status:</strong>
                </div>
                <div className="mx-2 flex2">
                  <select
                    name="withdraw_status"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option
                      disabled
                      value={"In progress"}
                      selected={
                        fundraiserDetail?.withdraw_status == "In progress"
                          ? "selected"
                          : ""
                      }
                    >
                      In progress
                    </option>
                    <option
                      value={"Verified"}
                      selected={
                        fundraiserDetail?.withdraw_status == "Verified"
                          ? "selected"
                          : ""
                      }
                    >
                      Verified
                    </option>
                  </select>
                  {(withdrawStatus == "" || withdrawStatus == undefined) && (
                    <div className="text-danger mt-2">
                      {error.paymentStatus}
                    </div>
                  )}
                </div>
              </div>
            )}
            {fundraiserDetail?.withdraw_status == "null" && (
              <div className="d-flex mb-2">
                <div className="mb-1 flex1">
                  <strong>Payment Status:</strong>
                </div>
                <div className="mx-2 flex2">
                  {fundraiserDetail?.withdraw_status}
                </div>
              </div>
            )}
            {fundraiserDetail?.withdraw_status == "In progress" && (
              <>
                <div className="d-flex mb-2">
                  <div className="mb-1 flex1">
                    <b>Upload Image</b>
                  </div>
                  <div className="mb-1 flex2">
                    <input
                      className="form-control"
                      type={"file"}
                      name="files"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={(e) => {
                        setFile({
                          files: e.target.files,
                          fileUrl: URL.createObjectURL(e.target.files[0]),
                        });
                      }}
                    />
                    {(file?.fileUrl == "" || file?.fileUrl == undefined) && (
                      <div className="text-danger mt-2">{error.image}</div>
                    )}
                  </div>
                </div>
                {file?.fileUrl && (
                  <div className="d-flex mb-4">
                    <div className="mb-1 flex1">
                      <strong>Proof of Payment:</strong>
                    </div>
                    <div className="mx-2 flex2">
                      <img
                        src={file?.fileUrl}
                        id="full-screenVideo1"
                        onClick={(e) => ShowModalPreview("full-screenVideo1")}
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                        alt="proofofpayment"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {fundraiserDetail?.withdraw_status == "Verified" && (
              <div className="d-flex mb-4">
                <div className="mb-1 flex1">
                  <strong>Proof of Payment:</strong>
                </div>
                <div className="mx-2 flex2">
                  <img
                    src={fundraiserDetail?.picture}
                    id="full-screenVideo1"
                    onClick={(e) => ShowModalPreview("full-screenVideo1")}
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                    alt="proofofpayment"
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="fun-donate-btn1" onClick={handleClose}>
            Close
          </Button>

          {loading ? (
            <Button className="btn__primary1 buttonPrimary1">
              <i
                className="fa mx-3 fa-circle-o-notch fa-spin"
                style={{fontSize: "24px"}}
              ></i>
            </Button>
          ) : (
            <>
              {fundraiserDetail?.withdraw_status == "In progress" && (
                <Button
                  className="btn__primary1 buttonPrimary1"
                  onClick={() => handleUpdate()}
                >
                  Update
                </Button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default PayoutDatatable;
