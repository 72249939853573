import React, { useState } from "react";
import eyeIcon from "../assets/img/Hide.png";
import hideEyeIcon from "../assets/img/Hide-2.png";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import ProfileCard from "../component/ProfileCard";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUserPassword } from "../Api-Calls/users";
import { SwalErrorMessage, SwalMessage } from "../component/sweetAlertMessage";

const ProfilePage = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const id = user?._id;
  const [passwords, setPasswords] = useState();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState();
  const [newPasswordError, setNewPasswordError] = useState();
  const [loader, setLoader] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState();
  const handlePasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
    setOldPasswordError();
    setNewPasswordError();
    setConfirmPasswordError();
  };
  const handleClear = () => {
    setPasswords();
  };
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    form.classList.add("was-validated");
    if (!passwords?.oldPassword || passwords?.oldPassword === "") {
      setOldPasswordError("password is required!");
    }
    if (!passwords?.newPassword || passwords?.newPassword === "") {
      setNewPasswordError("password is required!");
    }
    if (!passwords?.confirmPassword || passwords?.confirmPassword === "") {
      setConfirmPasswordError("password is required!");
    }
    if (
      !passwords?.oldPassword ||
      passwords?.oldPassword === "" ||
      !passwords?.newPassword ||
      passwords?.newPassword === "" ||
      !passwords?.confirmPassword ||
      passwords?.confirmPassword === ""
    ) {
      SwalErrorMessage("Oops!", "Please fill all fields!", "error", true);
      return;
    }

    if (passwords?.newPassword !== passwords?.confirmPassword) {
      SwalErrorMessage(
        "Oops!",
        "new password and confirm password does not match!",
        "error",
        true
      );
      return;
    }

    try {
      setLoader(true);
      const res = await updateUserPassword({
        id,
        oldPassword: passwords?.oldPassword,
        newPassword: passwords?.newPassword,
      });
      if (res?.status == 200) {
        localStorage.setItem("user", JSON.stringify(res?.result));
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        setLoader(false);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="Account Setting" desc="Account setting" />
              <div className="page-inner">
                <div className="row">
                  <div className="col-md-8">
                    <div className="card card-with-nav">
                      <div className="card-body">
                        <div className="row mt-4">
                          <div className="col-md-6 mx-auto">
                            <div className="form-group form-group-default">
                              <label>Current Password</label>
                              <div className="position-relative">
                                <input
                                  type={`${
                                    showOldPassword ? "text" : "password"
                                  }`}
                                  className="form-control outline"
                                  id="oldPassword"
                                  name="oldPassword"
                                  placeholder="Please enter your old password here"
                                  aria-describedby="password_addon"
                                  value={passwords?.oldPassword ?? ""}
                                  minLength="8"
                                  onChange={handlePasswordChange}
                                  required
                                />
                                <div
                                  className="input-group-append align-items-end"
                                  style={{
                                    position: "absolute",
                                    top: "30%",
                                    right: "-30px",
                                    transform: "translate(-50%, -70%)",
                                  }}>
                                  <button
                                    className="btn btn-sm btn-link pb-0"
                                    type="button"
                                    onClick={() =>
                                      setShowOldPassword(!showOldPassword)
                                    }>
                                    <img
                                      width="18"
                                      src={
                                        !showOldPassword ? eyeIcon : hideEyeIcon
                                      }
                                      alt="icon"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {oldPasswordError ? (
                              <p className="text-danger mb-0">
                                {oldPasswordError}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-6 mx-auto">
                            <div className="form-group form-group-default">
                              <label>New Password</label>
                              <div className="position-relative">
                                <input
                                  type={`${
                                    showNewPassword ? "text" : "password"
                                  }`}
                                  className="form-control outline"
                                  id="newPassword"
                                  name="newPassword"
                                  placeholder="Please enter your new password here"
                                  aria-describedby="password_addon"
                                  value={passwords?.newPassword ?? ""}
                                  minLength="8"
                                  onChange={handlePasswordChange}
                                  required
                                />
                                <div
                                  className="input-group-append align-items-end"
                                  style={{
                                    position: "absolute",
                                    top: "30%",
                                    right: "-30px",
                                    transform: "translate(-50%, -70%)",
                                  }}>
                                  <button
                                    className="btn btn-sm btn-link pb-0"
                                    type="button"
                                    onClick={() =>
                                      setShowNewPassword(!showNewPassword)
                                    }>
                                    <img
                                      width="18"
                                      src={
                                        !showNewPassword ? eyeIcon : hideEyeIcon
                                      }
                                      alt="icon"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {newPasswordError ? (
                              <p className="text-danger mb-0">
                                {newPasswordError}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-6 mx-auto">
                            <div className="form-group form-group-default">
                              <label>Confirm Password</label>
                              <div className="position-relative">
                                <input
                                  type={`${
                                    showConfirmPassword ? "text" : "password"
                                  }`}
                                  className="form-control outline"
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  placeholder="Please reenter your new password here"
                                  aria-describedby="password_addon"
                                  value={passwords?.confirmPassword ?? ""}
                                  minLength="8"
                                  onChange={handlePasswordChange}
                                  required
                                />
                                <div
                                  className="input-group-append align-items-end"
                                  style={{
                                    position: "absolute",
                                    top: "30%",
                                    right: "-30px",
                                    transform: "translate(-50%, -70%)",
                                  }}>
                                  <button
                                    className="btn btn-sm btn-link pb-0"
                                    type="button"
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }>
                                    <img
                                      width="18"
                                      src={
                                        !showConfirmPassword
                                          ? eyeIcon
                                          : hideEyeIcon
                                      }
                                      alt="icon"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {confirmPasswordError ? (
                              <p className="text-danger ">
                                {confirmPasswordError}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="text-right mt-3 mb-3">
                          <button
                            className="fun-donate-btn1 mx-3"
                            onClick={handleClear}>
                            Clear
                          </button>
                          {loader ? (
                            <button className="btn__primary1 buttonPrimary1">
                              <i
                                className="fa mx-3 fa-circle-o-notch fa-spin"
                                style={{ fontSize: "24px" }}></i>
                            </button>
                          ) : (
                            <button
                              className="btn__primary1 buttonPrimary1"
                              onClick={handleUpdatePassword}>
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <ProfileCard />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ProfilePage;
