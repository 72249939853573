import React, {useEffect, useState} from "react";
import Footer from "./footer";
import Header from "../component/header";
import Sidemenu from "../component/sidemenu";
import Jumbotran from "../component/Jumbotran";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {getCampaignStories} from "../Api-Calls/campaignStories";
import CampaignStoryDatatable from "../component/CampaignStoryDatatable";
const CampaignStoryList = () => {
  const [fundraiser, setFundraiser] = useState([]);

  useEffect(() => {
    getCampaignStoryData();
  }, []);

  const getCampaignStoryData = async () => {
    const res = await getCampaignStories();
    try {
      if (res?.status === 200) {
        const fundraiserList = res?.data?.result;
        setFundraiser(fundraiserList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Description",
      dataIndex: "description",
    },

    {
      title: "Collected Amount",
      dataIndex: "collectedAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Transactions",
      dataIndex: "transaction",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="Success Stories" desc="success stories" campaignStories="campaignStories"/>
              <div className="page-inner">
                <div className="row">
                  <div className="col-md-12">
                    <CampaignStoryDatatable
                      columns={columns}
                      fundraiser={fundraiser}
                      setFundraiser={setFundraiser}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default CampaignStoryList;
