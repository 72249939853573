import React, { useState } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getSupporterById,
  updateSupporterByIdDash,
} from "../Api-Calls/supporter";
import Loader from "./Loader";
import UserNameLength from "./UserNameLength";
import { SwalErrorMessage, SwalMessage } from "./sweetAlertMessage";
import userPicture from "../assets/img/userDefaultImg.png";

const TransactionDatatable = (props) => {
  const { donationDetails, id, setDonationDetails, columns } = props;
  const [tagModal, setTagModal] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState();
  const [loading, setLoading] = useState(false);
  const showTagModal = (e, detail) => {
    setTransactionDetail(detail);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };

  const data = [];

  for (let i = 0; i < donationDetails?.length; i++) {
    data.push({
      sr: donationDetails[i]?.seqId,
      username: donationDetails[i]?.personalInfo?.username,
      email: donationDetails[i]?.personalInfo?.email,
      title: donationDetails[i]?.fundraise_id?.title,
      payment_status: donationDetails[i]?.payment_status,
      payment_method: donationDetails[i]?.payment_method,
      donationAmount: <>Rs. {donationDetails[i]?.amount}</>,
      platformFee: <> Rs. {donationDetails[i]?.tip}</>,
      paymobFee: (
        <>
          {" "}
          Rs.{" "}
          {(
            donationDetails[i]?.totalAmount -
            donationDetails[i]?.amount -
            donationDetails[i]?.tip
          ).toFixed(2)}
        </>
      ),
      totalAmount: <>Rs. {donationDetails[i]?.totalAmount}</>,

      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, donationDetails[i])}
            className="btn-sm btn-info">
            <i className="fa fa-eye"></i>
          </Button>
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [paymentStatus, setPaymentStaus] = useState("");
  const handleChange = (e) => {
    let { name, value } = e.target;

    setPaymentStaus({
      ...paymentStatus,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res = await updateSupporterByIdDash(
        transactionDetail?._id,
        paymentStatus
      );
      if (res?.status == 200) {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        handleDonations();
        handleClose();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const handleDonations = async () => {
    try {
      const res = await getSupporterById(id);
      if (res?.status === 200) {
        setDonationDetails(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ShowModalPreview = () => {
    var el = document.getElementById("full-screenVideo");
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Single Transaction Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <div className="d-flex mb-2">
                  <div className="mb-1 flex1">
                    <strong>Username:</strong>
                  </div>
                  <div className="mx-2 flex2 text-capitalize">
                    <UserNameLength
                      username={transactionDetail?.personalInfo?.username}
                      length={30}
                    />
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div className="mb-1 flex1">
                    <strong>Email:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {transactionDetail?.personalInfo?.email}
                  </div>
                </div>
                <div className="d-flex mb-2">
                  <div className="mb-1 flex1">
                    <strong>Profile Image:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    <img
                      src={
                        transactionDetail?.personalInfo?.picture != ""
                          ? transactionDetail?.personalInfo?.picture
                          : userPicture
                      }
                      style={{
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                      }}
                      alt="profileImage"
                    />
                  </div>
                </div>

                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Payment Method:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {transactionDetail?.payment_method}
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Donation Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {transactionDetail?.amount}
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Platform Fee:</strong>
                  </div>
                  <div className="mx-2 flex2">Rs. {transactionDetail?.tip}</div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Paymob Fee:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs.{" "}
                    {(
                      transactionDetail?.totalAmount -
                      transactionDetail?.amount -
                      transactionDetail?.tip
                    ).toFixed(2)}
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Total Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {transactionDetail?.totalAmount}
                  </div>
                </div>
                {transactionDetail?.payment_status == "Inprogress" && (
                  <div className="d-flex mb-4">
                    <div className="mb-1 flex1">
                      <strong>Payment Status:</strong>
                    </div>
                    <div className="mx-2 flex2">
                      <select
                        name="payment_status"
                        className="form-control"
                        onChange={handleChange}>
                        <option>Select Payment Status</option>
                        <option
                          value={"Inprogress"}
                          selected={
                            transactionDetail?.payment_status == "Inprogress"
                              ? "selected"
                              : ""
                          }>
                          Inprogress
                        </option>
                        <option
                          value={"Verified"}
                          selected={
                            transactionDetail?.payment_status == "Verified"
                              ? "selected"
                              : ""
                          }>
                          Verified
                        </option>
                      </select>
                    </div>
                  </div>
                )}
                {transactionDetail?.payment_status == "Pending" && (
                  <div className="d-flex mb-2">
                    <div className="mb-1 flex1">
                      <strong>Payment Status:</strong>
                    </div>
                    <div className="mx-2 flex2">
                      {transactionDetail?.payment_status}
                    </div>
                  </div>
                )}
                {transactionDetail?.payment_status == "Verified" && (
                  <div className="d-flex mb-2">
                    <div className="mb-1 flex1">
                      <strong>Payment Status:</strong>
                    </div>
                    <div className="mx-2 flex2">
                      {transactionDetail?.payment_status}
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="fun-donate-btn1" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default TransactionDatatable;
