import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import TotalPlatformDonation from "./dashboard/TotalPlatformDonation";
import AmountCard from "./dashboard/AmountCard";
import DonorsCard from "./dashboard/DonorsCard";
import TotalSubscribersCard from "./dashboard/TotalSubscribersCard";
import BarChartComp from "./dashboard/BarChartComp";
import TopCauses from "./dashboard/TopCauses";
import { getAllSubscriber } from "../Api-Calls/subscribe";
import { getCollectedAmount, getSupporterWithMonth } from "../Api-Calls/supporter";
import TopDonors from "./dashboard/TopDonors";
import {getFundraise} from "../Api-Calls/fundraiser";

const Main = () => {
  const [subscriberData, setSubscriberData] = useState();
  const [supporterData, setSupporterData] = useState([]);
  const [monthlyDonationData, setMonthlyDonationData] = useState([]);
  const [supporterLength, setSupporterLength] = useState();
  const [collectedAmount, setCollectedAmount] = useState();
  const [fundraiserLength, setFundraiserLength] = useState();

  const getSubscriber = async () => {
    try {
      const res = await getAllSubscriber();
      if (res?.status == 200) {
        setSubscriberData(res?.data?.result?.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSupporterData = async () => {
    try {
      const res = await getCollectedAmount();
      if (res?.status == 200) {
        setSupporterData(res?.data?.result);
        setSupporterLength(res?.data?.result?.length);
        const collectedAmount = res?.data?.result.reduce(
          (accumulator, item) => accumulator + item.amount+ item.tip,
          0
        );
        setCollectedAmount(collectedAmount);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSupporterWithMonths = async () => {
    const res = await getSupporterWithMonth();
    try {
      if (res?.status == 200) {
        setMonthlyDonationData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getFundraiseData = async () => {
    const res = await getFundraise();
    try {
      if (res?.status == 200) {
        setFundraiserLength(res?.data?.result?.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubscriber();
    getSupporterData();
    getSupporterWithMonths();
    getFundraiseData();
  }, []);
  return (
    <div className="page-inner ">
      <div className="dashboardflex mt-n5">
        <div className="d-flex-item">
          <TotalPlatformDonation amount={collectedAmount} />
        </div>
        <div className=" d-flex-item">
          <AmountCard amount={fundraiserLength} />
        </div>
        <div className="   d-flex-item">
          <DonorsCard donors={supporterLength} />
        </div>
        <div className="   d-flex-item">
          <TotalSubscribersCard subs={subscriberData} />
        </div>
      </div>
      <BarChartComp monthlyDonationData={monthlyDonationData} />
      <div className="row">
        <TopCauses />
        <TopDonors />
      </div>
    </div>
  );
};

export default Main;
