import React, {useState} from "react";
import "antd/dist/antd.css";
import {Table} from "antd";
import {Button, Modal} from "react-bootstrap";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getSupporterById,
  updateSupporterByIdDash,
} from "../Api-Calls/supporter";
import Loader from "./Loader";
import {SwalErrorMessage, SwalMessage} from "./sweetAlertMessage";
import userPicture from "../assets/img/userDefaultImg.png";
import moment from "moment";
import docxImage from "../assets/img/docx.png";
import pdfImage from "../assets/img/pdf.png";
import {useNavigate} from "react-router";
import UserNameLength from "./UserNameLength";

const UserFundraiserDatatable = (props) => {
  const navigate = useNavigate();
  const {fundraiseDetails, id, setFundraiseDetails, columns} = props;
  const [tagModal, setTagModal] = useState(false);
  const [fundraiserDetails, setFundraiserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const showTagModal = (e, detail) => {
    setFundraiserDetails(detail);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const gotoTransaction = (slug, title) => {
    localStorage.setItem("fundraiseTitle", title);
    navigate(`/transaction/${slug}`);
  };
  const data = [];

  for (let i = 0; i < fundraiseDetails?.length; i++) {
    data.push({
      sr: fundraiseDetails[i]?.seqId,
      username: fundraiseDetails[i]?.userId?.username,
      email: fundraiseDetails[i]?.userId?.email,
      title: fundraiseDetails[i]?.title,
      category: fundraiseDetails[i]?.category[0]?.slug,
      description: fundraiseDetails[i]?.description,
      collectedAmount: (
        <>
          {fundraiseDetails[i]?.collectedAmount == 0 ? "" : "Rs. "}
          {fundraiseDetails[i]?.collectedAmount}
        </>
      ),
      totalAmount: fundraiseDetails[i]?.totalAmount,
      endDate: moment(fundraiseDetails[i]?.endDate).format("L"),
      transaction: (
        <button
          onClick={(e) =>
            gotoTransaction(
              fundraiseDetails[i]?.slug,
              fundraiseDetails[i]?.title
            )
          }
          className="fun-donate-btn1"
        >
          View
        </button>
      ),

      status: fundraiseDetails[i]?.status,
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, fundraiseDetails[i])}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye"></i>
          </Button>
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [paymentStatus, setPaymentStaus] = useState("");
  const handleChange = (e) => {
    let {name, value} = e.target;

    setPaymentStaus({
      ...paymentStatus,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res = await updateSupporterByIdDash(
        fundraiserDetails?._id,
        paymentStatus
      );
      if (res?.status == 200) {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        handleDonations();
        handleClose();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const handleDonations = async () => {
    try {
      const res = await getSupporterById(id);
      if (res?.status === 200) {
        setFundraiseDetails(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ShowModalPreview = () => {
    var el = document.getElementById("full-screenVideo");
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Fundraiser Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                {fundraiserDetails?.userId && (
                  <>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Username:</strong>
                      </div>
                      <div className="mx-2 flex2 text-capitalize">
                        <UserNameLength
                          username={fundraiserDetails?.userId?.username}
                          length={30}
                        />
                      </div>
                    </div>

                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Email:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        {fundraiserDetails?.userId?.email}
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Phone No:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        {fundraiserDetails?.userId?.phoneNo}
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Profile Image:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        <img
                          src={
                            fundraiserDetails?.userId?.picture != ""
                              ? fundraiserDetails?.userId?.picture
                              : userPicture
                          }
                          style={{
                            height: "100px",
                            width: "100px",
                            objectFit: "cover",
                          }}
                          alt="profileImage"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>SeqID:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.seqId}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Title:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.title}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Description:</strong>
                  </div>
                  <div
                    className="mx-2 flex2 mb-3"
                    style={{maxHeight: "300px", overflowY: "auto"}}
                  >
                    {fundraiserDetails?.description}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Start Date:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {moment(fundraiserDetails?.startDate).format("L")}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>End Date:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {moment(fundraiserDetails?.endDate).format("L")}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Collected Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {fundraiserDetails?.collectedAmount}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Total Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {fundraiserDetails?.totalAmount}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Status:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.status}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Fundraiser Image:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    {fundraiserDetails?.imageUrl?.length > 0 &&
                      fundraiserDetails?.imageUrl?.map((item) => {
                        return (
                          <>
                            {
                              <div style={{position: "relative"}}>
                                <img
                                  src={item?.url}
                                  width="150px"
                                  height="120px"
                                  style={{objectFit: "cover"}}
                                  className="mx-1 mt-3"
                                  alt="fundraiserImage"
                                />
                              </div>
                            }
                          </>
                        );
                      })}
                  </div>
                </div>
                {fundraiserDetails?.documents?.length > 0 && (
                  <div className="d-flex mt-3">
                    <div className="mb-1 flex1">
                      <strong>Fundraiser Documents:</strong>
                    </div>
                    <div className="mx-2 d-flex flex2 flex-wrap">
                      {fundraiserDetails?.documents?.length > 0 &&
                        fundraiserDetails?.documents?.map((item) => {
                          return (
                            <>
                              {item?.type === "image" ? (
                                <div style={{position: "relative", marginBottom: "10px"}}>
                                  <img
                                    style={{borderRadius: "12px"}}
                                    className="mx-2"
                                    src={item.url}
                                    width="70px"
                                    height="70px"
                                    alt="documentImage"
                                  />
                                </div>
                              ) : item?.type === "docx" ? (
                                <div style={{position: "relative",marginBottom: "10px"}}>
                                  <img
                                    style={{borderRadius: "12px"}}
                                    className="mx-2"
                                    src={docxImage}
                                    width="70px"
                                    height="70px"
                                    alt="documentDocx"
                                  />
                                </div>
                              ) : (
                                item?.type === "pdf" && (
                                  <div style={{position: "relative",marginBottom: "10px"}}>
                                    <img
                                      style={{borderRadius: "12px"}}
                                      className="mx-2"
                                      src={pdfImage}
                                      width="70px"
                                      height="70px"
                                      alt="documentPdf"
                                    />
                                  </div>
                                )
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="fun-donate-btn1" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default UserFundraiserDatatable;
