import React from "react";
import ProfileComp from "./profile";
import Sidebar from "./sidebar";

const Sidemenu = () => {
  return (
    <div className="sidebar sidebar-style-2">
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <ProfileComp />
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default Sidemenu;
