import React from "react";
import Header from "../header";
import Sidemenu from "../sidemenu";
import Jumbotran from "./Jumbotran";
import Footer from "../../pages/footer";
import { useState, useEffect } from "react";
import { getBlogs } from "../../Api-Calls/blogs";
import BlogListTable from "./BlogListTable";
export default function BlogList() {

  const [blogs, setBlogs] = useState();
  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  useEffect(() => {
    const getBlogsFun = () => {
      getBlogs().then((res) => {
        if (res?.data?.status == "success") {
          const blogList = res?.data?.result?.result;
          setBlogs(blogList);
        }
      });
    };
    getBlogsFun();
  }, []);
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran page="Blogs" desc="blogs" />
            <div className="p-3 shadow my-5 ">
              <BlogListTable
                columns={columns}
                blogs={blogs}
                setBlogs={setBlogs}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
