import { Table } from "antd";
import { useEffect, useState } from "react";
import { getTopDonors } from "../../Api-Calls/supporter";

export default function UserDonationTable() {

  const [topDonors, setTopDonors] = useState([]);

  const getTopDonorData = async () => {
    const res = await getTopDonors();
    try {
      if (res?.status == 200) {
        setTopDonors(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTopDonorData();
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: "Donors",
      dataIndex: "Donors",
    },
    {
      title: "Causes",
      dataIndex: "Causes",
    },
    {
      title: "Donated Amount",
      dataIndex: "DonatedAmount",
    },
  ];
  const data = [];

  for (let i = 0; i < topDonors?.length; i++) {
    data.push({
      Donors: topDonors[i]?.personalInfo?.username,
      Causes: topDonors[i]?.fundraise_id?.title,
      DonatedAmount: <>Rs. {topDonors[i]?.amount}</>,
    });
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <div className="top-donors card col-6 top-donor-container">
      <div className="card-header">
        <div className="card-title">Top Donors</div>
      </div>

      {data && (
        <Table
          pagination={{
            pageSize: 6,
          }}
          columns={columns}
          dataSource={data}
        />
      )}
    </div>
  );
}
