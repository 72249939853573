import React, { useEffect, useState } from "react";
import Datatable from "../component/Datatable";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import Sidemenu from "../component/sidemenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer";
import { ContactData } from "../Api-Calls/contactUs";
const Contact = () => {
  const [contactData, setContactData] = useState([]);
  useEffect(() => {
    getContactData();
  }, []);
  const getContactData = async () => {
    try {
      const res = await ContactData();
      if (res?.status == 200) {
        const contactUsList = res?.data?.result;
        const reversedContactUsList = contactUsList
          ? [...contactUsList].reverse()
          : [];
        setContactData(reversedContactUsList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
     {
      title: "Sr",
      dataIndex: "sr",
    },
    {
      title: "Full Name",
      dataIndex: "fname",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Subject",
      dataIndex: "subject",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran
              page="Contact Us"
              desc="contact us"
              contactus="conatctus"
            />
            <div key={"data"} className="page-inner">
              <Datatable
                columns={columns}
                contactData={contactData}
                setContactData={setContactData}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
