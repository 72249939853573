import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getUserFundraiser} from "../Api-Calls/users";
import Header from "../component/header";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Jumbotran from "../component/Jumbotran";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";
import UserFundraiserDatatable from "../component/UserFundraiserDatatable";
const UserFundraiser = (props) => {
  const {id} = useParams();
  const [fundraiseDetails, setFundraiseDetails] = useState([]);

  useEffect(() => {
    if (fundraiseDetails) handleDonations();
  }, []);
  const handleDonations = async () => {
    const res = await getUserFundraiser(id);
    try {
      if (res?.status === 200) {
        setFundraiseDetails(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Fundraise Name",
      dataIndex: "title",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Collected Amount",
      dataIndex: "collectedAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Transactions",
      dataIndex: "transaction",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran
              page="All User Fundraisers"
              desc="User Fundraisers"
              userFundraiser="userFundraiser"
            />
            <div className="page-inner">
              <h1 style={{color: "#002866"}}>
                <div className="d-flex justify-content-around">
                  <strong>{localStorage.getItem("user_name")}</strong>
                  <strong>{localStorage.getItem("user_email")}</strong>
                </div>
              </h1>
              <UserFundraiserDatatable
                columns={columns}
                fundraiseDetails={fundraiseDetails}
                setFundraiseDetails={setFundraiseDetails}
                id={id}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserFundraiser;
