import { Link } from "react-router-dom";

const Jumbotran = (props) => {
  return (
    <div className="panel-header bg-primary-gradient">
      <div className="page-inner py-5">
        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
          <div>
            <h2 className="text-white pb-2 fw-bold">{props?.page}</h2>
            <h5
              className="text-white op-7 mb-2"
              style={{ color: "#fff !important", opacity: "1" }}>
              <Link className="text-decoration-none" to={"/dashboard"}>
                {" "}
                Dashboard{" "}
              </Link>{" "}
              {props?.desc && <i className="fa fa-angle-right "></i>}{" "}
              <span style={{ opacity: ".57" }}>{props?.desc}</span>
            </h5>
          </div>

          <div className="ml-md-auto py-2 py-md-0">

            <Link
              to="/blog-form"
              className="fun-donate-btn2">
              Add Blog
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jumbotran;
