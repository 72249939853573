import axios from "axios";
import { config } from "./axiosHeader";

export const addStory = async (formData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/story/add-story`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateStory = async (id, formData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/story/update-story/${id}`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getStories = async () => {
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/story/get-stories`);
};

export const deleteStory = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/story/delete-story/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteStoryImage = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/story/delete-story-image/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};