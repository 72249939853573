import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { logout } from "./Api-Calls/authenticate";

const PrivateRoute = ({ children }) => {
  const token = Cookies.get("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const userToken = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    if (!token) {
      logout({ token: userToken, user });
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
  }, []);

  return token ? children : <Navigate to="/login" />;
};
export default PrivateRoute;
