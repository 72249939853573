import { SubIcons } from "../../data/Assets";

export default function TotalSubscribersCard({ subs }) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center ">
          <img className="cardIcon" src={SubIcons} alt="Donation" />
          <div className="ml-3">
            <h3 className="cardTitle">{subs ? subs : 0}</h3>
            <p>Subscribers</p>
          </div>
        </div>
      </div>
    </div>
  );
}
