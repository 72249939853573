import {isEmpty} from "lodash";
import React, {useEffect, useState} from "react";
import {Accordion} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getUserById} from "../Api-Calls/users";
import userPicture from "../assets/img/userDefaultImg.png";

const ProfileComp = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
      setUrl(user?.picture);
    }, 500);
  }, [JSON.parse(localStorage.getItem("user"))]);
  return (
    <Accordion className="user">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="profile-info">
          <div className="user ml-0">
            <div className="avatar-sm float-left mr-2">
              {!loader && (
                <img
                  className="mx-2"
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                  }}
                  src={url ? url : userPicture}
                />
              )}
            </div>
            <div className="info">
              <a
                data-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <span className="d-flex text-capitalize">{user?.username}</span>
              </a>
              <div className="clearfix"></div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="nav">
            <li>
              <Link to={"/profile"}>
                <span className="link-collapse">My Profile</span>
              </Link>
            </li>

            <li>
              <Link to="/setting">
                <span className="link-collapse">Settings</span>
              </Link>
            </li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ProfileComp;
