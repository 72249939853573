import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const para = window.location.pathname;
  return (
    <ul className="nav nav-primary">
      <li className={para == "/dashboard" ? "nav-item active" : "nav-item"}>
        <Link to={"/dashboard"}>
          <i className="fa fa-home"></i>
          <p>Dashboard</p>
        </Link>
      </li>

      <li className={para == "/users" ? "nav-item active" : "nav-item"}>
        <Link to={"/users"}>
          <i className="fa fa-user"></i>
          <p>Users</p>
        </Link>
      </li>
      <li className={para == "/admin-users" ? "nav-item active" : "nav-item"}>
        <Link to={"/admin-users"}>
          <i className="fa fa-user"></i>
          <p>Admin Users</p>
        </Link>
      </li>
      <li className={para == "/category" ? "nav-item active" : "nav-item"}>
        <Link to={"/category"}>
          <i className="fa fa-universal-access"></i>
          <p>Categories</p>
        </Link>
      </li>
      <li className={para == "/fundraiser" ? "nav-item active" : "nav-item"}>
        <Link to={"/fundraiser"}>
          <i className="fa fa-dollar"></i>
          <p>Fundraisers</p>
        </Link>
      </li>
      <li className={para == "/completed-fundraiser" ? "nav-item active" : "nav-item"}>
        <Link to={"/completed-fundraiser"}>
          <i className="fa fa-table"></i>
          <p>Completed Cases</p>
        </Link>
      </li>
      <li className={para == "/supporters" ? "nav-item active" : "nav-item"}>
        <Link to={"/supporters"}>
          <i className="fa fa-money"></i>
          <p>Payments</p>
        </Link>
      </li>
      <li className={para == "/payouts" ? "nav-item active" : "nav-item"}>
        <Link to={"/payouts"}>
          <i className="fa fa-university"></i>
          <p>Payouts</p>
        </Link>
      </li>
      <li className={para == "/blogs" ? "nav-item active" : "nav-item"}>
        <Link to={"/blogs"}>
          <i className="fa fa-child"></i>
          <p>Blogs</p>
        </Link>
      </li>
      <li
        className={para == "/success-stories" ? "nav-item active" : "nav-item"}>
        <Link to={"/success-stories"}>
          <i className="fa fa-book"></i>
          <p>Success Stories</p>
        </Link>
      </li>
      <li className={para == "/contact-us" ? "nav-item active" : "nav-item"}>
        <Link to={"/contact-us"}>
          <i className="fa fa-envelope-o"></i>
          <p>Contact Us</p>
        </Link>
      </li>
      <li className={para == "/volunteer" ? "nav-item active" : "nav-item"}>
        <Link to={"/volunteer"}>
          <i className="fa fa-user-plus"></i>
          <p>Volunteer</p>
        </Link>
      </li>
      <li className={para == "/subscribers" ? "nav-item active" : "nav-item"}>
        <Link to={"/subscribers"}>
          <i className="fa fa-users"></i>
          <p>Subscribers</p>
        </Link>
      </li>
    </ul>
  );
};

export default Sidebar;
