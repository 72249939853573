import { useEffect, useState } from "react";
import Profile from "../../assets/img/profile.jpg";
import { getTopFundraiser } from "../../Api-Calls/fundraiser";

export default function TopCauses() {

  const [fundraiser, setFundraiser] = useState([]);
  const getTopCases = async () => {
    try {
      const res = await getTopFundraiser();
      if (res?.status == 200) {
        setFundraiser(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTopCases();
  }, []);

  return (
    <div className="col-md-6">
      <div className="card">
        <div className="card-header">
          <div className="card-title">Top Causes</div>
        </div>
        <div className="card-body">
          <div className="card-list top-cause-container">
            {fundraiser && fundraiser?.length > 0 ? (
              <>
                {fundraiser?.map((item, key) => {
                  return (
                    <div className="item-list" key={key}>
                      <div className="avatar">
                        <img
                          src={item?.imageUrl[0]?.url}
                          alt={item?.title}
                          className="avatar-img rounded"
                        />
                      </div>
                      <div className="info-user ml-3">
                        <div
                          style={{
                            color: "#858585",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}>
                          {item?.title}
                        </div>
                        <div
                          className="status text-capitalize "
                          style={{
                            color: "#F9CB40",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}>
                          {item?.category[0]?.title?.toLowerCase()} Funding
                        </div>
                        <div
                          className="status text-primary font-weight-bold"
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                          }}>
                          Rs. {item?.collectedAmount} / Rs. {item?.totalAmount}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="text-center nodatafound mb-5">
                  <img src="https://ketto.gumlet.io/assets/images/browse-campaign/no-results.png?w=350&dpr=1.0" />
                  <h6>No results found</h6>
                  <p>
                    Try adjusting your search filters to find what you are
                    looking for.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
