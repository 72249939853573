import React, {useEffect, useState} from "react";
import Logo from "../assets/img/PoweredByVariationWhite.svg";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {logout} from "../Api-Calls/authenticate";
import Cookies from "js-cookie";
import userPicture from "../assets/img/userDefaultImg.png";

const Header = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
      setUrl(user?.picture);
    }, 500);
  }, [JSON.parse(localStorage.getItem("user"))]);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await logout({token});
      if (res?.status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        Cookies.remove("token");
        navigate(`/login`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="main-header">
      <div className="logo-header" data-background-color="blue">
        <Link to="/dashboard" className="logo text-center">
          <img
            src={Logo}
            alt="navbar brand"
            className="navbar-brand"
            style={{width: "90%"}}
          />
        </Link>
        <button
          className="navbar-toggler sidenav-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="icon-menu"></i>
          </span>
        </button>
        <button className="topbar-toggler more">
          <i className="icon-options-vertical"></i>
        </button>
        <div className="nav-toggle">
          <button className="btn btn-toggle toggle-sidebar">
            <i className="icon-menu"></i>
          </button>
        </div>
      </div>

      <nav
        className="navbar navbar-header navbar-expand-lg"
        data-background-color="blue2"
      >
        <div className="container-fluid">
          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li className="nav-item dropdown hidden-caret">
              <a
                className="dropdown-toggle profile-pic"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <div className="avatar-sm">
                  {!loader && (
                    <img
                      className="mx-2"
                      style={{
                        height: "35px",
                        width: "35px",
                        borderRadius: "50%",
                      }}
                      src={url ? url : userPicture}
                    />
                  )}
                </div>
              </a>
              <ul className="dropdown-menu dropdown-user animated fadeIn">
                <div className="dropdown-user-scroll scrollbar-outer">
                  <li>
                    <div className="user-box">
                      <div className="avatar-lg">
                        {!loader && (
                          <img
                            className="mx-2"
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "50%",
                            }}
                            src={url ? url : userPicture}
                          />
                        )}
                      </div>
                      <div className="u-text">
                        <h4>{user?.username}</h4>
                        <p className="text-muted mb-2">{user?.email}</p>
                        <Link to={"/profile"} className="profile-btn ">
                          View Profile
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                    <Link to={"/profile"} className="dropdown-item">
                      My Profile
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to={"/setting"}>
                      Account Setting
                    </Link>
                    <div className="dropdown-divider"></div>
                    <div onClick={handleLogout} className="dropdown-item">
                      Logout
                    </div>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
