import axios from "axios";
import { config, header } from "./axiosHeader";

export const getUser = async () => {
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}admin/get-admin-user`, config());
};

export const getUserById = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/admin/get-admin-by-id/${id}`,
    config
  );
};
export const getUserList = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/get-user-list`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getUserFundraiser = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/user-fundraiser/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserDonations = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/user-donations/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getAdminUserList = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/get-admin-user`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUser = async (id, formdata) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/update-admin/${id}`,
    formdata,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const updateSupporterUser = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/update-user-status/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateUserPassword = async (payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/admin/update-password/${payload.id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deleteUser = async (id, reason) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/admin/delete-user/${id}`,
    {
      data:{
        reason
      },
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },       
    },
  );
};
export const deleteAdminUser = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/admin/delete-admin/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },       
    },
  );
};
