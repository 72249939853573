/* eslint-disable no-lone-blocks */
import React, {useEffect, useState} from "react";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import Sidemenu from "../component/sidemenu";
import {useNavigate} from "react-router";
import Footer from "./footer";
import swal from "sweetalert";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addFundraise,
  deleteDocument,
  deleteImage,
  getFundraiseById,
  updateFundraise,
  deleteFundrasierVideo,
} from "../Api-Calls/fundraiser";
import {useParams} from "react-router";
import moment from "moment";
import {getCategory} from "../Api-Calls/category";
import {isEmpty, pick} from "lodash";
import VideoPreviewModal from "../component/VideoPreviewModal";
import ImgCrop from "antd-img-crop";
import {Upload} from "antd";
import {SwalErrorMessage, SwalMessage} from "../component/sweetAlertMessage";
import docxImage from "../assets/img/docx.png";
import pdfImage from "../assets/img/pdf.png";

const initialState = {
  title: "",
  startDate: new Date(),
  category: [],
  imageUrl: [],
  endDate: new Date(),
  description: "",
  totalAmount: "",
  tag: "",
  collectedAmount: 0,
  status: "",
};

const AddFundraise = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [isEditPage, setIsEditPage] = useState(() => {
    return !isEmpty(id);
  });
  const userData = JSON.parse(localStorage.getItem("user"));
  const [loader, setLoader] = useState(false);
  const [isCategoryArray, setIsCategoryArray] = useState(false);
  const [file, setFile] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [video, setVideo] = useState([]);
  const [titleError, setTitleError] = useState();
  const [amountError, setAmountError] = useState();
  const [descError, setDescError] = useState();
  const [statusError, setStatusError] = useState();
  const [funCatError, setFunCatError] = useState(null);
  const [funTagError, setFunTagError] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [category, setCategory] = useState([]);
  const [fundraise, setFundraise] = useState(() => initialState);
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    getFundraise();
    getCategoryData();
  }, []);
  useEffect(() => {
    if (!id) setFundraise(initialState);
  }, [id]);
  const handleClear = () => {
    setFundraise(initialState);
    setFileList([]);
    setDocuments();
  };
  const handleOnChangeImages = ({fileList: newFileList}) => {
    setImageError(null);
    setFileList(newFileList);
    setFile({
      files: newFileList,
      fileUrl: URL.createObjectURL(newFileList[0].originFileObj),
    });
  };
  const getCategoryData = async () => {
    const res = await getCategory();
    try {
      if (res?.status == 200) {
        setCategory(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formData = new FormData();

  const handleDeleteimage = (e, key) => {
    let url = key?.split("/")[4]?.split("?")[0];
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this image.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteImage(id, {url});
          let data = fundraise?.imageUrl?.filter((item) => item?.url !== key);
          setFundraise({...fundraise, imageUrl: data});
          if (data?.length == 0) {
            setFileList([]);
          }
          swal("Poof! Your selected image has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const handleDeleteDocument = (e, key) => {
    let url = key?.url?.split("/")[4]?.split("?")[0];
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this image.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteDocument(id, {url});
          let data = fundraise?.documents?.filter((item) => item !== key);
          setFundraise({...fundraise, documents: data});
          swal("Poof! Your selected image has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const handleDeleteVideo = (e, key) => {
    let url = key[0]?.url?.split("/")[4]?.split("?")[0];
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this video.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteFundrasierVideo(id, {url});
          let data = fundraise?.video?.filter(
            (item) => item.url !== key[0].url
          );
          console.log(data);
          setFundraise({...fundraise, video: data});
          swal("Poof! Your selected video has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const handleChange = (e) => {
    let {name, value} = e.target;
    if (name === "totalAmount" && "collectedAmount") {
      value = Number(value);
    }
    if (name === "category") {
      value = Array(value);
      setIsCategoryArray(true);
    }
    setFundraise({
      ...fundraise,
      [name]: value,
    });
    if (name === "title" && value) {
      setTitleError();
    }
    if (name === "category" && value) {
      setFunCatError(null);
    }
    if (name === "tag" && value) {
      setFunTagError(null);
    }
    if (name === "endDate" && value) {
      const startDate = moment(fundraise.startDate);
      const endDate = moment(value);
      if (
        startDate.isAfter(endDate) === false ||
        startDate.isSame(endDate) === false
      ) {
        setEndDateError();
      }
    }
    if (name === "totalAmount" && value) {
      setAmountError();
    }
    if (name === "description" && value) {
      setDescError();
    }
    if (name === "status" && value) {
      setStatusError();
    }
  };
  const fieldValidation = () => {
    let check = true;
    const startDate = moment(fundraise.startDate);
    const endDate = moment(fundraise.endDate);

    if (fundraise?.title === "") {
      check = false;
      setTitleError("title is required!");
    }
    if (fundraise.category.length === 0) {
      check = false;
      setFunCatError("Category is  required!");
    }
    if (fundraise.tag === "") {
      check = false;
      setFunTagError("Tag is  required!");
    }
    if (fileList.length === 0) {
      check = false;
      setImageError("Image is required!");
    }
    if (startDate.isAfter(endDate) || startDate.isSame(endDate)) {
      check = false;
      setEndDateError("End date should not be equal or less than start date!");
    }
    if (fundraise?.totalAmount === "") {
      check = false;
      setAmountError("total amount is required!");
    }
    if (fundraise?.description === "" || !fundraise?.description) {
      check = false;
      setDescError("description is required!");
    }
    return check;
  };
  const handleSubmit = async () => {
    if (file?.files?.length > 0) {
      Array?.from(file?.files)?.forEach((item, index) => {
        formData?.append("files", item?.originFileObj);
      });
    }
    if (fundraise?.imageUrl?.length > 0) {
      let imgArr = fundraise?.imageUrl;
      imgArr?.map((item, index) => {
        let url = item?.url?.split("/")[4]?.split("?")[0];
        formData?.append("imageUrl", url);
      });
    }
    if (documents?.files?.length > 0) {
      Array?.from(documents?.files)?.forEach((item, index) => {
        formData?.append("documents", item);
      });
    }
    if (fundraise?.documents?.length > 0) {
      let imgArr = fundraise?.documents;
      imgArr?.map((item, index) => {
        let url = item?.url?.split("/")[4]?.split("?")[0];
        formData?.append("documents", url);
      });
    }
    if (video?.files?.length > 0) {
      Array?.from(video?.files)?.forEach((item, index) => {
        formData?.append("video", item);
      });
    }
    if (fundraise?.video?.length > 0) {
      let videoArr = fundraise?.video;
      videoArr?.map((item, index) => {
        let url = item?.url?.split("/")[4]?.split("?")[0];
        formData?.append("video", url);
      });
    }
    formData?.append("category", fundraise?.category);
    formData?.append("title", fundraise?.title);
    formData?.append("description", fundraise?.description);
    formData?.append("startDate", fundraise?.startDate);
    formData?.append("endDate", fundraise?.endDate);
    formData?.append("status", fundraise?.status);
    formData?.append("totalAmount", fundraise?.totalAmount);
    formData?.append("tag", fundraise?.tag);
    formData?.append("collectedAmount", fundraise?.collectedAmount);
    formData?.append("adminUserId", userData?._id);
    if (!isCategoryArray) {
      formData.delete("category");
    }
    if (fieldValidation()) {
      setLoader(true);
      try {
        const res = isEditPage
          ? await updateFundraise(id, formData)
          : await addFundraise(formData);
        if (res?.status == 200 || res?.status == 201) {
          setFundraise(
            pick(res?.data?.result || fundraise, Object.keys(initialState))
          );
          {
            isEditPage
              ? SwalMessage(res?.data?.status, res?.data?.message, "success")
              : SwalMessage(res?.data?.status, res?.data?.message, "success");
          }
          setLoader(false);
          setTimeout(() => {
            navigate("/fundraiser");
          }, [2000]);
        } else if (!res?.data?.status == "error") {
          SwalErrorMessage(
            "Oops!" + " " + res?.data?.status,
            res?.data?.message || "Some thing went wrong",
            "error",
            true
          );
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    } else {
      setLoader(false);
    }
    getFundraise();
  };
  const getFundraise = async () => {
    if (!isEmpty(id))
      try {
        const res = await getFundraiseById(id);
        if (res?.status == 200) {
          setFundraise(res?.data?.result);
        }
        if (res?.data?.result?.imageUrl?.length > 0) {
          setFileList((prev) => [
            // ...prev,
            {
              thumbUrl: res?.data?.result?.imageUrl[0]?.url,
            },
          ]);
        }
      } catch (error) {
        console.log(error);
      }
  };
  const ShowModalPreview = (media) => {
    setModalShow(true);
    setMediaURL(media);
  };

  let fileObj = [];
  let fileArray = [];
  const uploadMultipleFiles = (e) => {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }

    let detail = {
      files: e.target.files,
      fileUrl: fileArray,
    };
    setFile(detail);
  };
  let documentObj = [];
  let documentArray = [];
  const uploadMultipleDocuments = (e) => {
    documentObj.push(e.target.files);
    for (let i = 0; i < documentObj[0].length; i++) {
      documentArray.push(URL.createObjectURL(documentObj[0][i]));
    }

    let detail = {
      files: e.target.files,
      fileUrl: documentArray,
    };
    setDocuments(detail);
  };
  let vidoeObj = [];
  let vidoeArray = [];
  const uploadVideo = (e) => {
    vidoeObj.push(e.target.files);
    for (let i = 0; i < vidoeObj[0].length; i++) {
      vidoeArray.push(URL.createObjectURL(vidoeObj[0][i]));
    }

    let detail = {
      files: e.target.files,
      fileUrl: vidoeArray,
    };
    setVideo(detail);
  };
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran
              page={isEditPage ? "Edit Fundraiser" : "Add Fundraiser"}
              desc={isEditPage ? "Edit Fundraiser" : "Add Fundraiser"}
            />
            <div className="page-inner">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-with-nav">
                    <div className="card-body">
                      <div className="row mt-3">
                        <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Enter Title"
                              value={fundraise?.title || ""}
                              onChange={handleChange}
                            />
                          </div>
                          {titleError ? (
                            <p className="text-danger">{titleError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mx-auto">
                          <div className="form-group form-group-default">
                            <label
                              style={{
                                fontSize: "10.5px !important",
                                letterSpacing: "0.06em",
                                textTransform: "uppercase",
                              }}
                            >
                              Select Fundraise Category
                            </label>
                            <select
                              name="category"
                              className={`form-control ${
                                funCatError !== null && "border-danger "
                              } `}
                              onChange={handleChange}
                            >
                              <option
                                disabled={
                                  fundraise?.category?.length > 0 ? true : false
                                }
                                value=""
                              >
                                Select Category
                              </option>
                              {category?.map((category, index) => {
                                return (
                                  <option
                                    value={category._id}
                                    selected={
                                      fundraise?.category._id == category?._id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {category?.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <p className="text-danger  my-2 ms-2 text-start  ">
                            {funCatError}
                          </p>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="fund-img">
                            <label>Add Image</label>
                            <div style={{position: "relative"}}>
                              {fundraise?.imageUrl?.length > 0 && (
                                <>
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="false"
                                    id="imageDeleteIcon1"
                                    onClick={(e) =>
                                      handleDeleteimage(
                                        e,
                                        fileList[0]?.thumbUrl
                                      )
                                    }
                                  ></i>
                                </>
                              )}
                            </div>
                            <ImgCrop
                              aspect={1 / 1.2}
                              showGrid
                              rotationSlider
                              // aspectSlider
                              id="profileUploadFile"
                              showReset
                            >
                              <Upload
                                action=""
                                listType="picture-card"
                                fileList={fileList}
                                onChange={handleOnChangeImages}
                              >
                                {fileList?.length < 1 && "+ Upload"}
                              </Upload>
                            </ImgCrop>
                            <p className="text-danger  my-2 ms-2 text-start  ">
                              {imageError}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-group-default">
                            <label> SELECT FUNDRAISER TAG</label>
                            <select
                              className="form-control"
                              name="tag"
                              onChange={handleChange}
                              value={fundraise?.tag || ""}
                            >
                              <option
                              disabled={fundraise?.tag ? true : false}
                              value=""
                            >
                              Select Tag
                            </option>
                            <option
                              value={"Zakat"}
                              selected={
                                fundraise?.tag === "Zakat"
                                  ? "selected"
                                  : ""
                              }
                            >
                              Zakat
                            </option> 
                            <option
                              value={"Zakat and Sadka"}
                              selected={
                                fundraise?.tag === "Zakat and Sadka"
                                  ? "selected"
                                  : ""
                              }
                            >
                              Zakat and Sadka
                            </option>
                            <option
                              value={"Sadka"}
                              selected={
                                fundraise?.tag === "Sadka "
                                  ? "selected"
                                  : ""
                              }
                            >
                              Sadka
                            </option>
                            <option
                              value={"General Donation"}
                              selected={
                                fundraise?.tag === "General Donation"
                                  ? "selected"
                                  : ""
                              }
                            >
                              General Donation
                            </option>
                            <option
                              value={"Sponsorship"}
                              selected={
                                fundraise?.tag === "Sponsorship"
                                  ? "selected"
                                  : ""
                              }
                            >
                              Sponsorship
                            </option>
                            </select>
                          </div>
                          {funTagError ? (
                            <p className="text-danger">{funTagError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group form-group-default mb-4">
                            <label>Add Documents</label>
                            <input
                              type="file"
                              multiple
                              id="uploadDocument"
                              accept="*/*"
                              className="form-control"
                              name="documents"
                              placeholder="Add Documents"
                              onChange={uploadMultipleDocuments}
                            />
                          </div>
                          {documents?.fileUrl ? (
                            <>
                              {documents?.fileUrl?.map((image) => {
                                return (
                                  <img
                                    style={{
                                      width: "150px",
                                      height: "120px",
                                      marginRight: "10px",
                                      objectFit: "cover",
                                      borderRadius: "12px",
                                      cursor: "pointer",
                                      marginBottom: "10px",
                                    }}
                                    src={image}
                                    alt=""
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <div className="d-flex  flex-wrap">
                              {fundraise?.documents?.length > 0 &&
                                fundraise?.documents?.map((item) => {
                                  return (
                                    <>
                                      {item?.type === "image" ? (
                                        <div
                                          style={{
                                            position: "relative",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <img
                                            style={{borderRadius: "12px"}}
                                            className="mx-2"
                                            src={item.url}
                                            width="150px"
                                            height="120px"
                                            onClick={(e) =>
                                              ShowModalPreview(item?.url)
                                            }
                                            alt="documents"
                                          />
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="false"
                                            id="imageDeleteIcon"
                                            onClick={(e) =>
                                              handleDeleteDocument(e, item)
                                            }
                                          ></i>
                                        </div>
                                      ) : item?.type === "docx" ? (
                                        <div
                                          style={{
                                            position: "relative",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <img
                                            style={{borderRadius: "12px"}}
                                            className="mx-2"
                                            src={docxImage}
                                            width="150px"
                                            height="120px"
                                            alt="documents"
                                          />
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="false"
                                            id="imageDeleteIcon"
                                            onClick={(e) =>
                                              handleDeleteDocument(e, item)
                                            }
                                          ></i>
                                        </div>
                                      ) : (
                                        item?.type === "pdf" && (
                                          <div
                                            style={{
                                              position: "relative",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <img
                                              style={{borderRadius: "12px"}}
                                              className="mx-2"
                                              src={pdfImage}
                                              width="150px"
                                              height="120px"
                                              alt="documents"
                                            />
                                            <i
                                              className="fa fa-times"
                                              aria-hidden="false"
                                              id="imageDeleteIcon"
                                              onClick={(e) =>
                                                handleDeleteDocument(e, item)
                                              }
                                            ></i>
                                          </div>
                                        )
                                      )}
                                    </>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                        <div className="col-12">
                          <div className="mb-4">
                            <div className="form-group form-group-default mb-4">
                              <label>Add Video</label>
                              <input
                                type="file"
                                id="uploadVideo"
                                accept="video/*"
                                className="form-control"
                                name="video"
                                placeholder="Add Video"
                                onChange={uploadVideo}
                              />
                            </div>
                          </div>
                          {video?.fileUrl ? (
                            <>
                              <div className="d-flex mb-4">
                                <video
                                  style={{
                                    width: "350px",
                                    border: "2px solid #000",
                                    borderRadius: "8px",
                                  }}
                                  muted
                                  controls
                                  loading="lazy"
                                >
                                  <source
                                    src={video.fileUrl[0]}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex  flex-wrap">
                                {fundraise?.video &&
                                  fundraise?.video?.length > 0 && (
                                    <div style={{position: "relative"}}>
                                      <video
                                        style={{
                                          width: "300px",
                                          border: "2px solid #000",
                                          borderRadius: "8px",
                                        }}
                                        muted
                                        controls
                                        loading="lazy"
                                      >
                                        <source
                                          src={fundraise?.video[0].url}
                                          type="video/mp4"
                                        />
                                      </video>
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="false"
                                        id="videoDeleteIcon"
                                        onClick={(e) =>
                                          handleDeleteVideo(e, fundraise?.video)
                                        }
                                      ></i>
                                    </div>
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-group-default">
                            <label>Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="datepicker"
                              name="startDate"
                              value={moment(
                                fundraise?.startDate || new Date()
                              ).format("YYYY-MM-DD")}
                              placeholder="Birth Date"
                              onChange={handleChange}
                              min={moment().format("YYYY-MM-DD")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-group-default">
                            <label>End Date</label>
                            <input
                              type="date"
                              name="endDate"
                              className="form-control"
                              id="datepicker"
                              placeholder="Birth Date"
                              onChange={handleChange}
                              value={moment(
                                fundraise?.endDate || new Date()
                              ).format("YYYY-MM-DD")}
                              min={moment().format("YYYY-MM-DD")}
                            />
                          </div>
                          <p className="text-danger  my-2 ms-2 text-start  ">
                            {endDateError}
                          </p>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="form-group form-group-default">
                            <label>Total Amount</label>
                            <input
                              type="text"
                              className="form-control"
                              name="totalAmount"
                              maxLength={7}
                              value={fundraise?.totalAmount || ""}
                              placeholder="Enter Amount"
                              onChange={handleChange}
                            />
                          </div>
                          <p className="text-danger">{amountError}</p>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-group-default">
                            <label> Status</label>
                            <select
                              className="form-control"
                              name="status"
                              onChange={handleChange}
                              value={fundraise?.status || ""}
                            >
                              <option disabled value="">
                                Select Status
                              </option>
                              <option
                                disabled
                                value={"Pending"}
                                selected={
                                  fundraise?.status == "Pending"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Pending
                              </option>
                              <option
                                value={"Active"}
                                selected={
                                  fundraise?.status == "Active"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Active
                              </option>
                              <option
                                value={"Rejected"}
                                selected={
                                  fundraise?.status == "Rejected"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Rejected
                              </option>
                            </select>
                          </div>
                          {statusError ? (
                            <p className="text-danger">{statusError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="row mt-3 mb-1">
                        <div className="col-md-12">
                          <div className="form-group form-group-default">
                            <label>Description</label>
                            <textarea
                              className="form-control"
                              name="description"
                              maxLength={1000}
                              value={fundraise?.description || ""}
                              placeholder="Enter Description"
                              onChange={handleChange}
                              rows="3"
                            ></textarea>
                          </div>
                          {descError ? (
                            <p className="text-danger">{descError}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div className="text-right mt-3 mb-3">
                        <button
                          className="fun-donate-btn1 mx-3"
                          onClick={handleClear}
                        >
                          Clear
                        </button>

                        {isEditPage ? (
                          <>
                            {loader ? (
                              <button className="btn__primary1 buttonPrimary1">
                                <i
                                  className="fa mx-3 fa-circle-o-notch fa-spin"
                                  style={{fontSize: "24px"}}
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn__primary1 buttonPrimary1 mt-2"
                                onClick={handleSubmit}
                              >
                                Update Fundraiser{" "}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {loader ? (
                              <button className="btn__primary1 buttonPrimary1">
                                <i
                                  className="fa mx-3 fa-circle-o-notch fa-spin"
                                  style={{fontSize: "24px"}}
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn__primary1 buttonPrimary1 mt-2"
                                onClick={handleSubmit}
                              >
                                Add Fundraiser{" "}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <VideoPreviewModal
            media={mediaURL}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddFundraise;
