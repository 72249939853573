import React, { useState } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import Loader from "./Loader";
import { deleteFundraiser } from "../Api-Calls/fundraiser";
import swal from "sweetalert";
import moment from "moment";
import { SwalErrorMessage, SwalMessage } from "../component/sweetAlertMessage";
import userPicture from "../assets/img/userDefaultImg.png";
import docxImage from "../assets/img/docx.png";
import pdfImage from "../assets/img/pdf.png";
import UserNameLength from "./UserNameLength";
import VideoPreviewModal from "./VideoPreviewModal";

const FundraiseDatatable = (props) => {
  const { fundraiser, setFundraiser, columns } = props;
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const [fundraiserDetails, setFundraiserDetails] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const showTagModal = (details) => {
    setFundraiserDetails(details);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const navigate = useNavigate();
  const handleDeleteFundraiser = (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this image.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteFundraiser(id).then((res) => {
            SwalMessage(res?.data?.status, res?.data?.message, "success");
            let filteredData = fundraiser?.filter((item) => item?._id !== id);
            setFundraiser(filteredData);
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const gotoTransaction = (slug, title) => {
    localStorage.setItem("fundraiseTitle", title);
    navigate(`/transaction/${slug}`);
  };
  const handleEditFundraise = (id) => {
    navigate(`/edit-fundraise/${id}`);
  };
  const data = [];

  for (let i = 0; i < fundraiser?.length; i++) {
    data.push({
      sr: fundraiser[i]?.seqId,
      title: fundraiser[i]?.title,
      category: fundraiser[i]?.category[0]?.slug,
      description: fundraiser[i]?.description,
      collectedAmount: (
        <>
          {fundraiser[i]?.collectedAmount == 0 ? "" : "Rs. "}
          {fundraiser[i]?.collectedAmount}
        </>
      ),
      totalAmount: fundraiser[i]?.totalAmount,
      endDate: moment(fundraiser[i]?.endDate).format("L"),
      transaction: (
        <button
          onClick={(e) =>
            gotoTransaction(fundraiser[i]?.slug, fundraiser[i]?.title)
          }
          className="fun-donate-btn1">
          View
        </button>
      ),

      status: fundraiser[i]?.status,
      status: (
        <span
          className={`${
            fundraiser[i]?.status === "Pending"
              ? "btn btn-sm btn-outline-warning font-weight-bold"
              : fundraiser[i]?.status === "Active"
              ? "btn btn-sm btn-outline-primary font-weight-bold"
              : "btn btn-sm btn-outline-success font-weight-bold"
          }`}>
          {fundraiser[i]?.status}
        </span>
      ),
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(fundraiser[i])}
            className="action-button-view">
            <i className="fa fa-eye"></i>
          </Button>{" "}
          &nbsp;
          <Button
            onClick={(e) => handleEditFundraise(fundraiser[i]?._id)}
            className="action-button-edit">
            <i className="fa fa-edit"></i>
          </Button>{" "}
          &nbsp;
          <Button
            onClick={(e) => handleDeleteFundraiser(fundraiser[i]?._id)}
            className="action-button-delete">
            <i className="fa fa-trash"></i>
          </Button>
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [paymentStatus, setPaymentStaus] = useState("");

  const handleChange = (e) => {
    let { name, value } = e.target;

    setPaymentStaus({
      ...paymentStatus,
      [name]: value,
    });
  };

  const [loading, setLoading] = useState(false);
  const ShowModalPreview = (media) => {
    setModalShow(true);
    setMediaURL(media);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Fundraiser Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                {fundraiserDetails?.userId ? (
                  <>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Username:</strong>
                      </div>
                      <div className="mx-2 flex2 text-capitalize">
                        <UserNameLength
                          username={fundraiserDetails?.userId?.username}
                          length={30}
                        />
                      </div>
                    </div>

                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Email:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        {fundraiserDetails?.userId?.email}
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Phone No:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        {fundraiserDetails?.userId?.phoneNo}
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Profile Image:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        <img
                          src={
                            fundraiserDetails?.userId?.picture != ""
                              ? fundraiserDetails?.userId?.picture
                              : userPicture
                          }
                          style={{
                            height: "100px",
                            width: "100px",
                            objectFit: "cover",
                          }}
                          onClick={(e) =>
                            ShowModalPreview(
                              fundraiserDetails?.userId?.picture != ""
                                ? fundraiserDetails?.userId?.picture
                                : userPicture
                            )
                          }
                          alt="profileImage"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Username:</strong>
                      </div>
                      <div className="mx-2 flex2 text-capitalize">
                        {fundraiserDetails?.adminUserId?.username}
                      </div>
                    </div>

                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Email:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        {fundraiserDetails?.adminUserId?.email}
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Phone No:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        {fundraiserDetails?.adminUserId?.phoneNo}
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mb-1 flex1">
                        <strong>Profile Image:</strong>
                      </div>
                      <div className="mx-2 flex2">
                        <img
                          src={
                            fundraiserDetails?.adminUserId?.picture != ""
                              ? fundraiserDetails?.adminUserId?.picture
                              : userPicture
                          }
                          id="full-screenVideo"
                          onClick={(e) =>
                            ShowModalPreview(
                              fundraiserDetails?.adminUserId?.picture != ""
                                ? fundraiserDetails?.adminUserId?.picture
                                : userPicture
                            )
                          }
                          style={{
                            height: "100px",
                            width: "100px",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          alt="profileImage"
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>SeqID:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.seqId}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Title:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.title}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Description:</strong>
                  </div>
                  <div
                    className="mx-2 flex2 mb-3"
                    style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {fundraiserDetails?.description}
                  </div>
                </div>
                {fundraiserDetails?.tag && (
                  <div className="d-flex">
                    <div className="mb-1 flex1">
                      <strong>Tag Name:</strong>
                    </div>
                    <div className="mx-2 flex2 mb-3">
                      {fundraiserDetails?.tag}
                    </div>
                  </div>
                )}
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Start Date:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {moment(fundraiserDetails?.startDate).format("L")}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>End Date:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {moment(fundraiserDetails?.endDate).format("L")}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Collected Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {fundraiserDetails?.collectedAmount}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Total Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {fundraiserDetails?.totalAmount}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Status:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.status}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Fundraiser Image:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    {fundraiserDetails?.imageUrl?.length > 0 &&
                      fundraiserDetails?.imageUrl?.map((item) => {
                        return (
                          <>
                            {
                              <div style={{ position: "relative" }}>
                                <img
                                  src={item?.url}
                                  width="150px"
                                  height="120px"
                                  style={{ objectFit: "cover" }}
                                  className="mx-1 mt-3"
                                  alt="fundraiserImage"
                                  onClick={(e) => ShowModalPreview(item?.url)}
                                />
                              </div>
                            }
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="mb-1 flex1">
                    <strong>Fundraiser Documents:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2 flex-wrap">
                    {fundraiserDetails?.documents?.length > 0 &&
                      fundraiserDetails?.documents?.map((item) => {
                        return (
                          <>
                            {item?.type === "image" ? (
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "10px",
                                }}>
                                <img
                                  style={{ borderRadius: "12px" }}
                                  className="mx-2"
                                  src={item.url}
                                  width="70px"
                                  height="70px"
                                  alt="documentImage"
                                  onClick={(e) => ShowModalPreview(item?.url)}
                                />
                              </div>
                            ) : item?.type === "docx" ? (
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "10px",
                                }}>
                                <img
                                  style={{ borderRadius: "12px" }}
                                  className="mx-2"
                                  src={docxImage}
                                  width="70px"
                                  height="70px"
                                  alt="documentDocx"
                                />
                              </div>
                            ) : (
                              item?.type === "pdf" && (
                                <div
                                  style={{
                                    position: "relative",
                                    marginBottom: "10px",
                                  }}>
                                  <img
                                    style={{ borderRadius: "12px" }}
                                    className="mx-2"
                                    src={pdfImage}
                                    width="70px"
                                    height="70px"
                                    alt="documentPdf"
                                  />
                                </div>
                              )
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
                {fundraiserDetails?.video?.length > 0 && (
                  <div className="d-flex">
                    <div className="mb-1 flex1">
                      <strong>Video:</strong>
                    </div>
                    <div className="mx-2 flex2">
                      <video
                        style={{
                          width: "300px",
                          border: "2px solid #000",
                          borderRadius: "8px",
                        }}
                        muted
                        controls
                        loading="lazy">
                        <source
                          src={fundraiserDetails?.video[0]?.url}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="fun-donate-btn1" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <VideoPreviewModal
        media={mediaURL}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ToastContainer />
    </>
  );
};

export default FundraiseDatatable;
