import { DonorIcon } from "../../data/Assets";

export default function DonorsCard({ donors }) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center ">
          <img className="cardIcon" src={DonorIcon} alt="Donation" />
          <div className="ml-3">
            <h3 className="cardTitle">{donors ? donors : 0}</h3>
            <p>Donors</p>
          </div>
        </div>
      </div>
    </div>
  );
}
