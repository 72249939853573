
import axios from "axios";
import { config, header } from "./axiosHeader";

export const getAllSubscriber = async () => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/get-subscriber`,
    config
  );
};
export const deleteSubscriber = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/delete-subscriber/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};