import React, {useEffect, useState} from "react";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import ProfileCard from "../component/ProfileCard";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {isEmpty, pick} from "lodash";
import {updateUser, getUserById} from "../Api-Calls/users";
import moment from "moment";
import Loader from "../component/Loader";
import {SwalErrorMessage, SwalMessage} from "../component/sweetAlertMessage";
const initialState = {
  username: "",
  email: "",
  birthDate: new Date(),
  aboutMe: "",
  role: "",
  picture: {},
  phoneNo: "",
  address: "",
  gender: "",
};
const ProfilePage = () => {
  const [profile, setProfile] = useState(initialState);
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [loader, setLoader] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [errorState, setErrorState] = useState({});
  useEffect(() => {
    handleGetUser(user?._id);
    setUserId(user?._id);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const handleClear = () => {
    setProfile(initialState);
  };
  const handleGetUser = async (id) => {
    if (!isEmpty(id))
      try {
        const res = await getUserById(id);
        if (res?.status == 200) {
          localStorage.setItem("user", JSON.stringify(res?.data?.result?.user));
          setProfile(
            pick(res?.data?.result?.user || profile, Object.keys(initialState))
          );
        }
      } catch (error) {
        console.log(error);
      }
  };

  const handleChange = (e) => {
    let {name, value} = e.target;

    if (name && name in errorState) {
      setErrorState({...errorState, [name]: false});
    }
    if (value === "") {
      setErrorState({...errorState, [name]: true});
    }
    if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      setErrorState({...errorState, emailFormat: true});
    } else if (name === "email" && /\S+@\S+\.\S+/.test(value)) {
      setErrorState({...errorState, emailFormat: false});
    }
    if (name === "phoneNo") {
      if (value.length < 15) {
        setProfile({
          ...profile,
          phoneNo: value,
        });
      }
    } else {
      setProfile({
        ...profile,
        [name]: value,
      });
    }
  };
  const handleSubmit = async () => {
    const {username, email, birthDate, gender, phoneNo} = profile;
    const formData = new FormData();
    const fileInput = document.getElementById("profileUploadFile");
    if (fileInput.files.length > 0) {
      Array?.from(file?.files)?.forEach((item) => {
        formData?.append("files", item);
      });
    }
    formData?.append("username", profile?.username);
    formData?.append("email", profile?.email);
    formData?.append("birthDate", profile?.birthDate);
    formData?.append("gender", profile?.gender);
    formData?.append("phoneNo", profile?.phoneNo);
    formData?.append("role", profile?.role);
    formData?.append("address", profile?.address);
    formData?.append("aboutMe", profile?.aboutMe);
    if (username && email && birthDate && gender && phoneNo) {
      setLoader(true);
      const res = await updateUser(userId, formData);
      try {
        if (res?.status == 200) {
          localStorage.setItem("user", JSON.stringify(res?.data?.result?.user));
          setProfile(
            pick(res?.data?.result?.user || profile, Object.keys(initialState))
          );

          SwalMessage(res?.data?.status, res?.data?.message, "success");
          handleGetUser(user?._id);
          setFile("");
          setLoader(false);
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    }
    if (!username) {
      setErrorState({...errorState, username: true});
    }
    if (!email) {
      setErrorState({...errorState, email: true});
    }
    if (!phoneNo) {
      setErrorState({...errorState, phoneNo: true});
    }
  };
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="User Profile" desc="User Profile" />
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <div className="page-inner">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="card card-with-nav">
                          <div className="card-body">
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="form-group form-group-default">
                                  <label>Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={handleChange}
                                    name="username"
                                    value={profile?.username || ""}
                                    placeholder="Name"
                                  />
                                </div>
                                {errorState?.username && (
                                  <p className="text-danger mt-0  ml-2 text-start">
                                    Username is required!
                                  </p>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-group-default">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                    className="form-control"
                                    value={profile?.email || ""}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="email"
                                  />
                                </div>
                                {errorState?.email ? (
                                  <p className="text-danger mt-0  ml-2 text-start">
                                    Email is required!
                                  </p>
                                ) : errorState.emailFormat ? (
                                  <p className="text-danger mt-0  ml-2 text-start">
                                    Email format is not correct!
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="form-group form-group-default">
                                  <label>Birth Date</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="datepicker"
                                    onChange={handleChange}
                                    name="birthDate"
                                    value={moment(
                                      profile?.birthDate || new Date()
                                    ).format("YYYY-MM-DD")}
                                    placeholder="Birth Date"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-group-default">
                                  <label>Gender</label>
                                  <select
                                    className="form-control"
                                    id="gender"
                                    onChange={handleChange}
                                    name="gender"
                                    value={profile?.gender || ""}
                                  >
                                    <option>male</option>
                                    <option>female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-group-default">
                                  <label>Phone</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={handleChange}
                                    name="phoneNo"
                                    value={profile?.phoneNo || ""}
                                    placeholder="Phone"
                                  />
                                </div>
                                {errorState?.phoneNo && (
                                  <p className="text-danger mt-0  ml-2 text-start">
                                    Phone no is required!
                                  </p>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-group-default">
                                  <label>Role</label>
                                  <select
                                    className="form-control"
                                    id="role"
                                    onChange={handleChange}
                                    name="role"
                                    value={profile?.role || ""}
                                  >
                                    <option>Select Role</option>
                                    <option>Super Admin</option>
                                    <option>Admin</option>
                                    <option>User</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-12">
                                <div className="form-group form-group-default">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={handleChange}
                                    name="address"
                                    value={profile?.address || ""}
                                    placeholder="Address"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3 mb-1">
                              <div className="col-md-12">
                                <div className="form-group form-group-default">
                                  <label>About Me</label>
                                  <textarea
                                    className="form-control"
                                    value={profile?.aboutMe || ""}
                                    onChange={handleChange}
                                    name="aboutMe"
                                    placeholder="About Me"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="row-mt-3">
                              <div className="form-group form-group-default">
                                <label>Picture</label>
                                <input
                                  type="file"
                                  id="profileUploadFile"
                                  accept="image/*"
                                  className="form-control"
                                  name="files"
                                  placeholder="Add Image"
                                  onChange={(e) => {
                                    setFile({
                                      files: e.target.files,
                                      fileUrl: URL.createObjectURL(
                                        e.target.files[0]
                                      ),
                                    });
                                  }}
                                />
                                {file?.files?.length > 0 && (
                                  <img
                                    width="100"
                                    height="100"
                                    style={{
                                      width: "130px",
                                      height: "100px",
                                      marginRight: "10px",
                                      marginTop: "10px",
                                      objectFit: "cover",
                                      borderRadius: "6px",
                                      cursor: "pointer",
                                    }}
                                    src={file?.fileUrl}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="text-right mt-3 mb-3">
                              <button
                                className="fun-donate-btn1 mx-3"
                                onClick={handleClear}
                              >
                                Clear
                              </button>
                              {loader ? (
                                <button className="btn__primary1 buttonPrimary1">
                                  <i
                                    className="fa mx-3 fa-circle-o-notch fa-spin"
                                    style={{fontSize: "24px"}}
                                  ></i>
                                </button>
                              ) : (
                                <button
                                  className="btn__primary1 buttonPrimary1"
                                  onClick={handleSubmit}
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ProfileCard profile={profile} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProfilePage;
