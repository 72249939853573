import React, { useEffect, useState } from "react";
import Footer from "./footer";
import Header from "../component/header";
import Sidemenu from "../component/sidemenu";
import Jumbotran from "../component/Jumbotran";
import "react-toastify/dist/ReactToastify.css";
import { getCampaignStories } from "../Api-Calls/campaignStories";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const CompletedFundraiser = () => {
  const [fundraiserLength, setFundraiserLength] = useState({
    total: 0,
    emergency: 0,
    medical: 0,
    education: 0,
    children: 0,
    hunger_free: 0,
    sports: 0,
    animal: 0,
    memorial: 0,
  });

  useEffect(() => {
    getFundraiseData();
  }, []);
  const getFundraiseData = async () => {
    const res = await getCampaignStories();
    try {
      if (res?.status === 200) {
        const fundraiserList = res?.data?.result;
        let emergencyCount = 0;
        let medicalCount = 0;
        let educationCount = 0;
        let childrenCount = 0;
        let hungerFreeCount = 0;
        let sportsCount = 0;
        let animalCount = 0;
        let memorialCount = 0;

        fundraiserList.forEach((item) => {
          if (item.seqId.includes("BBEMG")) {
            emergencyCount++;
          } else if (item.seqId.includes("BBMED")) {
            medicalCount++;
          } else if (item.seqId.includes("BBEDU")) {
            educationCount++;
          } else if (item.seqId.includes("BBCHN")) {
            childrenCount++;
          } else if (item.seqId.includes("BBHNG")) {
            hungerFreeCount++;
          } else if (item.seqId.includes("BBSPR")) {
            sportsCount++;
          } else if (item.seqId.includes("BBAML")) {
            animalCount++;
          } else if (item.seqId.includes("BBMRL")) {
            memorialCount++;
          }
        });

        setFundraiserLength({
          ...fundraiserLength,
          total: fundraiserList.length,
          medical: medicalCount,
          emergency: emergencyCount,
          education: educationCount,
          children: childrenCount,
          sports: sportsCount,
          animal: animalCount,
          memorial: memorialCount,
          hunger_free: hungerFreeCount,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fundraiserData = [
    fundraiserLength.total,
    fundraiserLength.emergency,
    fundraiserLength.medical,
    fundraiserLength.education,
    fundraiserLength.children,
    fundraiserLength.hunger_free,
    fundraiserLength.sports,
    fundraiserLength.animal,
    fundraiserLength.memorial,
  ];
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const dataSet = {
    labels: [
      "Total",
      "Emergency",
      "Medical",
      "Education",
      "Children",
      "HungerFree",
      "Sports",
      "Animal",
      "Memorial",
    ],
    datasets: [
      {
        label: "Completed Fundraiser Count",
        data: fundraiserData,
        backgroundColor: [
          // "#002866",
          "#3e95cd",
          "#8e5ea2",
          "#3cba9f",
          "#e8c3b9",
          "#c45850",
          "#8A2BE2",
          "#FFD700",
          "#FFA07A",
          "#87CEFA",
        ],
        borderColor: [
          "#3e95cd",
          "#8e5ea2",
          "#3cba9f",
          "#e8c3b9",
          "#c45850",
          "#8A2BE2",
          "#FFD700",
          "#FFA07A",
          "#87CEFA",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran
                page="Completed Fundraiser"
                desc="completed fundraiser"
                completedFundraiser="completedFundraiser"
              />
              <div className="page-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="card full-height">
                        <div className="card-body">
                          <div className="cardTitleBlue">
                            Completed Fundraiser
                          </div>
                          <div className="row py-3">
                            <div className="col-md-12">
                              <Bar
                                height={50}
                                width={200}
                                data={dataSet}
                                options={options}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedFundraiser;
