import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../src/assets/img/logo.png";

const Register = () => {
  return (
    <>
      <section className="d-flex">
        <div className="form--section">
          <div className="h-100 d-flex bg-white justify-content-center align-items-center">
            <div className="mx-auto app-login-box col-sm-12 col-md-10  col-lg-9">
              <div className="px-2">
                <img src={Logo} className="app-logo" loading="lazy"/>
                <h2>Welcome,</h2>
                <p>
                  It only takes a{" "}
                  <span className="text-primary">few seconds</span> to create
                  your account
                </p>
                <small></small>
                <hr />
              </div>
              <Row className="">
                <Col lg="6">
                  <div className="form-group">
                    <label for="exampleInputEmail1">
                      <span className="text-danger">*</span> Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email here..."
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label for="exampleInputName1">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      aria-describedby="NameHelp"
                      placeholder="Name here..."
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label for="exampleInputPassword1">
                      <span className="text-danger">*</span> Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      aria-describedby="PasswordHelp"
                      placeholder="Password here..."
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label for="exampleInputRPassword1">
                      <span className="text-danger">*</span> Repeat Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputRPassword1"
                      aria-describedby="RPasswordHelp"
                      placeholder="Repeat Password here..."
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-check d-flex align-items-center">
                    <input
                      name="check"
                      id="exampleCheck"
                      type="checkbox"
                      className="form-check-input ml-0"
                    />
                    <label
                      for="exampleCheck"
                      className="form-check-label mb-n2 mx-2 form-label">
                      Accept our{" "}
                      <span className="text-primary">
                        Terms and Conditions.
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <p>
                  Already have an account?{" "}
                  <span>
                    <Link to={"/login"} className="text-primary pointer">
                      Sign in
                    </Link>
                  </span>
                </p>
                <Link to={"/dashboard"} className="text-decoration-none">
                  <button className="btn btn-primary btn-lg rounded">
                    <strong>Create Account</strong>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="slider--section d-none d-md-block">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="overlay3">
                  <img
                    className="d-block w-100"
                    src="https://demo.dashboardpack.com/architectui-react-pro/static/media/citynights.8df3ac24685306a68061.jpg"
                    alt="Third slide"
                  />
                  <div
                    className="carousel-caption d-none d-md-block"
                    style={{ width: "80%" }}>
                    <h5 className="text-nowrap">
                      Scalable, Modular, Consistent
                    </h5>
                    <p>
                      Easily exclude the components you don't require.
                      Lightweight, consistent Bootstrap based styles across all
                      elements and components
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
