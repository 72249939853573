import React from 'react'

const Slider = () => {
  return (
    <div className="slider--section d-none d-md-block">
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-ride="carousel">
      <ol className="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          className="active"></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="1"></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="2"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="overlay1">
            <img
              className="d-block w-100"
              src="https://demo.dashboardpack.com/architectui-react-pro/static/media/city.4ac39fb472e963dbca5d.jpg"
              alt="First slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5 className="text-nowrap">Perfect Balance</h5>
              <p>
                ArchitectUI is like a dream. Some think it's too good to
                be true! Extensive collection of unified React Boostrap
                Components and Elements.
              </p>
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div className="overlay3">
            <img
              className="d-block w-100"
              src="https://demo.dashboardpack.com/architectui-react-pro/static/media/citynights.8df3ac24685306a68061.jpg"
              alt="Third slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5 className="text-nowrap">Complex, but lightweight</h5>
              <p>
                We've included a lot of components that cover almost all
                use cases for any type of application.
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item ">
          <div className="overlay2">
            <img
              className="d-block w-100"
              src="https://demo.dashboardpack.com/architectui-react-pro/static/media/citydark.0a91fa8083453354624f.jpg"
              alt="Second slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5 className="text-nowrap">
                Scalable, Modular, Consistent
              </h5>
              <p>
                Easily exclude the components you don't require.
                Lightweight, consistent Bootstrap based styles across all
                elements and components
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        className="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev">
        <span
          className="carousel-control-prev-icon"
          aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next">
        <span
          className="carousel-control-next-icon"
          aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  </div>
    )
}

export default Slider