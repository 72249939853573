import axios from "axios";
import { config } from "./axiosHeader";

export const getCategory = async () => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/category/get-categories`,
    config
  );
};

export const deleteCategory = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/category/delete-category/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const addCategory = async (payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/category/add-category`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateCategory = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/category/update-category/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
