import React, {useState} from "react";
import "antd/dist/antd.css";
import {Table} from "antd";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Button, Modal} from "react-bootstrap";
import {deleteUser, updateSupporterUser} from "../Api-Calls/users";
import swal from "sweetalert";
import moment from "moment";
import {SwalErrorMessage, SwalMessage} from "../component/sweetAlertMessage";
import Loader from "./Loader";
import userPicture from "../assets/img/userDefaultImg.png";
import {useNavigate} from "react-router";
import UserNameLength from "./UserNameLength";
import VideoPreviewModal from "./VideoPreviewModal";
const UserDatatable = (props) => {
  const navigate = useNavigate();
  const {userList, setUserList, columns, getUserListData} = props;
  const [userDetail, setUserDetail] = useState();
  const [userData, setUserData] = useState();
  const [tagModal, setTagModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateButtonShow, setUpdateButtonShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const showTagModal = (e, details) => {
    setUserDetail(details);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const handleDeleteUser = (id) => {
    try {
      swal({
        title: "Are you sure?",
        content: {
          element: "input",
          attributes: {
            placeholder: "Enter the reason for account deactivation",
            type: "text",
          },
        },
        text: "Once deleted, you will not be able to recover this user.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setUserLoading(true);
          deleteUser(id, willDelete)
            .then((res) => {
              SwalMessage(res?.data?.status, res?.data?.message, "success");
              setUserLoading(false);
              getUserListData();
            })
            .catch((error) => {
              SwalErrorMessage(
                "Oops!" + " " + error?.response?.data?.status,
                error?.response?.data?.message,
                "error",
                true
              );
            });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const gotoDonations = (id, username, email) => {
    localStorage.setItem("username", username);
    localStorage.setItem("useremail", email);
    navigate(`/user-transaction/${id}`);
  };
  const gotoFundraiser = (id, username, email) => {
    localStorage.setItem("user_name", username);
    localStorage.setItem("user_email", email);
    navigate(`/user-fundraiser/${id}`);
  };
  const data = [];
  for (let i = 0; i < userList?.length; i++) {
    data.push({
      sr: userList[i]?.seqId,
      username: userList[i]?.username,
      email: userList[i]?.email,
      date: moment(userList[i]?.updated).format("L"),
      role: userList[i]?.role ? userList[i]?.role : "User",
      is_donor:
        userList[i]?.is_donor === true ? (
          <button
            onClick={(e) =>
              gotoDonations(
                userList[i]?._id,
                userList[i]?.username,
                userList[i]?.email
              )
            }
            className="fun-donate-btn1"
          >
            Donations
          </button>
        ) : (
          "No"
        ),
      is_fundraiser:
        userList[i]?.is_fundraiser === true ? (
          <button
            onClick={(e) =>
              gotoFundraiser(
                userList[i]?._id,
                userList[i]?.username,
                userList[i]?.email
              )
            }
            className="fun-donate-btn1"
          >
            Fundraisers
          </button>
        ) : (
          "No"
        ),
      verified:
        userList[i]?.is_fundraiser === true &&
        userList[i]?.email_confirmation === "Approved"
          ? "Yes"
          : "No",
      status: (
        <span
          className={`${
            userList[i]?.status === "Pending"
              ? "btn btn-sm btn-outline-warning font-weight-bold"
              : userList[i]?.status === "Disabled"
              ? "btn btn-sm btn-outline-danger font-weight-bold"
              : "btn btn-sm btn-outline-success font-weight-bold"
          }`}
        >
          {userList[i]?.status}
        </span>
      ),
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, userList[i])}
            className="action-button-view"
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
          &nbsp;
          {userList[i]?.status !== "Disabled" && (
            <Button
              onClick={(e) => handleDeleteUser(userList[i]?._id)}
              className="action-button-delete"
            >
              <i className="fa fa-trash"></i>
            </Button>
          )}
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const handleChange = (e) => {
    let {name, value} = e.target;
    if (value === "Rejected"){
      setUpdateButtonShow(true);
    }else{
      setUpdateButtonShow(false);
    } 
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const handleUpdate = async () => {
    if (userData) {
      try {
        setLoading(true);
        const res = await updateSupporterUser(userDetail?._id, userData);
        if (res?.status) {
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          getUserListData();
          handleClose();
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
        setLoading(false);
      }
    }
  };
  const ShowModalPreview = (media) => {
    setModalShow(true);
    setMediaURL(media);
  };
  return (
    <>
      {userLoading ? (
        <Loader />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>User Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Username:</strong>
                  </div>
                  <div className="mx-2 flex2 text-capitalize">
                    <UserNameLength
                      username={userDetail?.username}
                      length={30}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Email:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.email}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Profile Picture:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    <div style={{position: "relative"}}>
                      <img
                        src={
                          userDetail?.picture != ""
                            ? userDetail?.picture
                            : userPicture
                        }
                        width="100px"
                        height="100px"
                        objectFit="cover"
                        className="mx-1 my-3"
                        alt="profileImage"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Role:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {userDetail?.role ? userDetail?.role : "User"}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Country:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.country}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Phone No:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.phoneNo}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Address:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.address}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>zipcode:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.zipcode}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Email Confirmation:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {userDetail?.email_confirmation}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Is_Donor:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {userDetail?.is_donor === true ? "Yes" : "No"}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Is_Fundraiser:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {userDetail?.is_fundraiser === true ? "Yes" : "No"}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Verifeid:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {userDetail?.is_fundraiser === true &&
                    userDetail?.email_confirmation === "Approved"
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Status:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    <select
                      name="status"
                      defaultValue={userDetail?.status}
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option disabled value="">
                        Select Status
                      </option>
                      <option
                        disabled
                        value={"Pending"}
                        selected={
                          userDetail?.status == "Pending" ? "selected" : ""
                        }
                      >
                        Pending
                      </option>
                      <option
                        value={"Rejected"}
                        selected={
                          userDetail?.status == "Rejected" ? "selected" : ""
                        }
                      >
                        Rejected
                      </option>
                      {userDetail?.status == "Disabled" && (
                        <option
                          value={"Disabled"}
                          selected={
                            userDetail?.status == "Disabled" ? "selected" : ""
                          }
                        >
                          Disabled
                        </option>
                      )}
                      <option
                        value={"Verified"}
                        selected={
                          userDetail?.status == "Verified" ? "selected" : ""
                        }
                      >
                        Verified
                      </option>
                    </select>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>National Id Card Number:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.cnicNo}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>How did you hear about us:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.hear_about}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>CNIC Picture:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    {userDetail?.cnic_picture?.length > 0 &&
                      userDetail?.cnic_picture?.map((item) => {
                        return (
                          <>
                            <div style={{position: "relative"}}>
                              <img
                                src={item?.url}
                                width="150px"
                                height="120px"
                                style={{objectFit: "cover"}}
                                className="mx-1 mt-3"
                                alt="cnicPicture"
                                id="full-screenVideo"
                                onClick={(e) => ShowModalPreview(item)}
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
              <Button className="btn__primary1 buttonPrimary1  d-flex"  onClick={handleClose}>
                Send Mail
              </Button>
              <Button className="fun-donate-btn1" onClick={handleClose}>
                Close
              </Button>
              {loading ? (
                <Button className="btn__primary1 buttonPrimary1">
                  <i
                    className="fa mx-3 fa-circle-o-notch fa-spin"
                    style={{fontSize: "24px"}}
                  ></i>
                </Button>
              ) : (
                userDetail?.cnicNo &&
                userDetail?.country &&
                userDetail?.phoneNo &&
                userDetail?.address &&
                userDetail?.zipcode &&
                userDetail?.hear_about &&
                userDetail?.cnic_picture && (
                  <Button
                    className="btn__primary1 buttonPrimary1"
                    onClick={() => handleUpdate()}
                  >
                    Update
                  </Button>
                )
              )}
              <VideoPreviewModal
                media={mediaURL}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </Modal.Footer> */}
            <Modal.Footer className="d-flex">
              <div className="d-flex justify-content-end">
                <Button className="fun-donate-btn1 mr-2" onClick={handleClose}>
                  Close
                </Button>
                {/* {updateButtonShow === true && (
                  <Button
                    className="btn__primary1 buttonPrimary1 mr-2"
                    onClick={() => handleUpdate()}
                  >
                    Update
                  </Button>
                )} */}
                {loading ? (
                  <Button className="btn__primary1 buttonPrimary1">
                    <i
                      className="fa mx-3 fa-circle-o-notch fa-spin"
                      style={{fontSize: "24px"}}
                    ></i>
                  </Button>
                ) : (
                  userDetail?.status !== "Verified" &&
                  ((userDetail?.cnicNo &&
                  userDetail?.country &&
                  userDetail?.phoneNo &&
                  userDetail?.address &&
                  userDetail?.zipcode &&
                  userDetail?.hear_about &&
                  userDetail?.cnic_picture) || updateButtonShow === true ? (
                    <Button
                      className="btn__primary1 buttonPrimary1"
                      onClick={() => handleUpdate()}
                    >
                      Update
                    </Button>
                  ) : (
                    <a
                      href={`https://mail.google.com/mail/u/0/?fs=1&to=${encodeURIComponent(
                        userDetail?.email
                      )}&tf=cm`}
                      target="_blank"
                    >
                      <Button className="btn__primary1 buttonPrimary1">
                        Send Mail
                      </Button>
                    </a>
                  ))
                )}
              </div>
              <VideoPreviewModal
                media={mediaURL}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </Modal.Footer>
          </Modal>
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default UserDatatable;
