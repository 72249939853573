import axios from "axios";
import { config } from "./axiosHeader";

export const getForgotPasswordDetails = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/admin/forgot-password-details/${id}`,
    config
  );
};

export const changePassword = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admin/change-password`,
    payload
  );
};

export const forgotPassword = async (payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admin/forgot-password`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
