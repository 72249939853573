import React, { useState } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { deleteSubscriber } from "../Api-Calls/subscribe";

const SubscriberDatatable = (props) => {
  const { subscriberData, setSubscriberData, columns } = props;
  const [tagModal, setTagModal] = useState(false);
  const [subscriberDetail, setSubscriberDetail] = useState();
  const showTagModal = (e, detail) => {
    setSubscriberDetail(detail);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };

  const subscriberDelete = (e, id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this subscriber.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteSubscriber(id);
          let data = subscriberData?.filter((item) => item?._id !== id);
          setSubscriberData(data);
          swal("Poof! Your subscriber has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const data = [];
  for (let i = 0; i < subscriberData?.length; i++) {
    data.push({
      sr: subscriberData[i]?.seqId,
      email: subscriberData[i]?.email,
      subscriber: subscriberData[i]?.isSubscribe == true ? "Yes" : "No",
      date: moment(subscriberData[i]?.created).format("L"),
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, subscriberData[i])}
            className="action-button-view">
            <i className="fa fa-eye"></i>
          </Button>{" "}
          <Button
            onClick={(e) => subscriberDelete(e, subscriberData[i]?._id)}
            className="action-button-delete">
            <i className="fa fa-trash"></i>
          </Button>
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
      <Modal show={tagModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Contact Us Detail</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Email:</strong>
              </div>
              <div className="mx-2 flex2">{subscriberDetail?.email}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Subscriber:</strong>
              </div>
              <div className="mx-2 flex2">
                {subscriberDetail?.isSubscribe == true ? "True" : "False"}
              </div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Date:</strong>
              </div>
              <div className="mx-2 flex2">
                {moment(subscriberDetail?.created).format("L")}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="fun-donate-btn1 mx-3" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default SubscriberDatatable;
