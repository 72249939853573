import { config } from "./axiosHeader";
import axios from "axios";

export const ContactData = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/contact-us/get-contacts`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getVolunteerData = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/volunteer/get-volunteers`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteContact = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/contact-us/delete-contact/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
