import React from "react";

const UserNameLength = ({username, length}) => {
  return (
    <>
      {username?.length > 30
        ? username?.slice(0, length) + "..."
        : username?.slice(0, length)}
    </>
  );
};

export default UserNameLength;
