import React from "react";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import Main from "../component/main";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";

const Home = () => {
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran page="Dashboard" desc="" />
            <Main />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
