/* eslint-disable no-lone-blocks */
import React, {useEffect, useState} from "react";
import Header from "../header";
import Sidemenu from "../sidemenu";
import {useLocation, useNavigate} from "react-router";
import swal from "sweetalert";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {addBlog, deleteBLogImage, updateBlog} from "../../Api-Calls/blogs";
import Footer from "../../pages/footer";
import Editor from "../Editor";
import Jumbotran from "./Jumbotran";
import {SwalErrorMessage, SwalMessage} from "../sweetAlertMessage";
export default function BlogForm() {
  const history = useLocation();

  const navigate = useNavigate();
  const [isEditPage, setIsEditPage] = useState(history?.state);
  const blogId = history?.state?._id;
  const [file, setFile] = useState(
    history.state ? history?.state?.attachments : []
  );

  const [showError, setShowError] = useState({
    title: "",
    description: "",
    meta_image_description: "",
    meta_description: "",
    focus_key_phrase: "",
    inbound_links: "",
    outbound_links: "",
  });

  const [loader, setLoader] = useState(false);
  const [blogData, setBlogData] = useState(
    history.state
      ? {
          title: history?.state?.title,
          imageUrl: history?.state?.attachments,
          description: history?.state?.description,
          meta_image_description: history?.state?.meta_image_description,
          meta_description: history?.state?.meta_description,
          focus_key_phrase: history?.state?.focus_key_phrase,
          inbound_links: history?.state?.inbound_links,
          outbound_links: history?.state?.outbound_links,
        }
      : {
          title: "",
          imageUrl: "",
          description: "",
          meta_image_description: "",
          meta_description: "",
          focus_key_phrase: "",
          inbound_links: "",
          outbound_links: "",
        }
  );
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const handleClear = () => {
    setBlogData({
      title: "",
      imageUrl: [],
      description: "",
      meta_image_description: "",
      meta_description: "",
      focus_key_phrase: "",
      inbound_links: "",
      outbound_links: "",
    });
  };
  const handleDeleteBlogImage = (e, key) => {
    let url = key?.url?.split("/")[4]?.split("?")[0];
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this image.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteBLogImage(blogId, {url});
          let data = blogData?.imageUrl?.filter((item) => item !== key);
          setBlogData({...blogData, imageUrl: data});
          swal("Poof! Your selected image has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  useEffect(() => {
    if (history.state) {
      const {
        title,
        description,
        attachments,
        meta_image_description,
        meta_description,
        focus_key_phrase,
        inbound_links,
        outbound_links,
      } = history.state;
      setBlogData({
        title: title,
        imageUrl: attachments,
        description,
        meta_image_description,
        meta_description,
        focus_key_phrase,
        inbound_links,
        outbound_links,
      });
      setFile(attachments);
    }
  }, []);
  const handleChange = (e) => {
    let {name, value} = e.target;
    setBlogData({
      ...blogData,
      [name]: value,
    });

    if (value !== "") {
      setShowError({
        ...showError,
        [name]: "",
      });
    }
  };
  const handleSubmit = async () => {
    const newErrors = {};
    if (blogData?.title === "") {
      newErrors.title = "Title is required!";
    }
    if (blogData?.description === "") {
      newErrors.description = "Description is required!";
    }
    if (blogData?.meta_image_description === "") {
      newErrors.meta_image_description = "Meta Image Description is required!";
    }
    if (blogData?.meta_description === "") {
      newErrors.meta_description = "Meta Description is required!";
    }
    if (blogData?.focus_key_phrase === "") {
      newErrors.focus_key_phrase = "Focus Key Phrase is required!";
    }
    if (blogData?.inbound_links === "") {
      newErrors.inbound_links = "Inbound link is required!";
    }
    if (blogData?.outbound_links === "") {
      newErrors.outbound_links = "Outbound link is required!";
    }
    setShowError(newErrors);
    if (
      blogData?.title === "" ||
      blogData?.description === "" ||
      blogData?.meta_image_description === "" ||
      blogData?.meta_description === "" ||
      blogData?.focus_key_phrase === "" ||
      blogData?.inbound_links === "" ||
      blogData?.outbound_links === ""
    ) {
      SwalErrorMessage("Oops!", "Please fill all fields!", "error", true);
      return;
    }
    const formData = new FormData();
    const fileInput = document.getElementById("uploadFile");
    if (fileInput.files.length > 0) {
      Array?.from(file?.files)?.forEach((item, index) => {
        formData?.append("attachments", item);
      });
    }
    formData?.append("title", blogData?.title);
    formData?.append(
      "meta_image_description",
      blogData?.meta_image_description
    );
    formData?.append("meta_description", blogData?.meta_description);
    formData?.append("focus_key_phrase", blogData?.focus_key_phrase);
    formData?.append("inbound_links", blogData?.inbound_links);
    formData?.append("outbound_links", blogData?.outbound_links);
    formData?.append("description", blogData?.description);
    if (blogData?.imageUrl?.length > 0) {
      let imgArr = blogData?.imageUrl;
      imgArr?.map((item, index) => {
        let url = item.url.split("/")[4]?.split("?")[0];
        formData?.append("attachments", url);
      });
    }
    try {
      setLoader(true);
      const res = isEditPage
        ? await updateBlog(history.state._id, formData)
        : await addBlog(formData);
      if (res?.status == 201 || res?.status == 200) {
        setBlogData({
          title: "",
          imageUrl: [],
          description: "",
        });
        setFile([]);
        {
          isEditPage
            ? SwalMessage(res?.data?.status, res?.data?.message, "success")
            : SwalMessage(res?.data?.status, res?.data?.message, "success");
        }
        setLoader(false);
        setTimeout(() => {
          navigate("/blogs");
        }, [2000]);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  const ShowModalPreview = (media) => {
    setModalShow(true);
    setMediaURL(media);
  };

  let fileObj = [];
  let fileArray = [];
  const uploadMultipleFiles = (e) => {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }

    let detail = {
      files: e.target.files,
      fileUrl: fileArray,
    };
    setFile(detail);
  };
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran page="Add Blog" desc="Add Blog" />
            <div className="page-inner">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-with-nav">
                    <div className="card-body">
                      <div className="row mt-3">
                      <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Enter Title"
                              value={blogData?.title || ""}
                              onChange={handleChange}
                            />
                          </div>
                          {showError.title ? (
                            <p className="text-danger">{showError.title}</p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>Focus Key phrase</label>
                            <input
                              type="text"
                              className="form-control"
                              name="focus_key_phrase"
                              placeholder="Enter Focus Key phrase"
                              value={blogData?.focus_key_phrase || ""}
                              onChange={handleChange}
                            />
                          </div>
                          {showError.focus_key_phrase ? (
                            <p className="text-danger">
                              {showError.focus_key_phrase}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>Meta Description</label>
                            <input
                              type="text"
                              className="form-control"
                              name="meta_description"
                              placeholder="Enter Meta Description"
                              value={blogData?.meta_description || ""}
                              onChange={handleChange}
                            />
                          </div>
                          {showError.meta_description ? (
                            <p className="text-danger">
                              {showError.meta_description}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>Meta Image Description</label>
                            <input
                              type="text"
                              className="form-control"
                              name="meta_image_description"
                              placeholder="Enter Meta Image Description"
                              value={blogData?.meta_image_description || ""}
                              onChange={handleChange}
                            />
                          </div>
                          {showError.meta_image_description ? (
                            <p className="text-danger">
                              {showError.meta_image_description}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>Inbound links</label>
                            <input
                              type="text"
                              className="form-control"
                              name="inbound_links"
                              placeholder="Enter Inbound links"
                              value={blogData?.inbound_links || ""}
                              onChange={handleChange}
                            />
                          </div>
                          {showError.inbound_links ? (
                            <p className="text-danger">
                              {showError.inbound_links}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>Outbound Links</label>
                            <input
                              type="text"
                              className="form-control"
                              name="outbound_links"
                              placeholder="Enter Outbound Links"
                              value={blogData?.outbound_links || ""}
                              onChange={handleChange}
                            />
                          </div>
                          {showError.outbound_links ? (
                            <p className="text-danger">
                              {showError.outbound_links}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                       
                        <div className="col-md-12 mb-3">
                          <div className="form-group form-group-default mb-4">
                            <label>Add Image</label>
                            <input
                              type="file"
                              multiple
                              id="uploadFile"
                              accept="image/png, image/jpeg, image/jpg"
                              className="form-control"
                              name="files"
                              placeholder="Add Image"
                              onChange={uploadMultipleFiles}
                            />
                          </div>
                          {file?.fileUrl ? (
                            <>
                              {file?.fileUrl?.map((image) => {
                                return (
                                  <img
                                    style={{
                                      width: "150px",
                                      height: "120px",
                                      marginRight: "10px",
                                      objectFit: "cover",
                                      borderRadius: "12px",
                                      cursor: "pointer",
                                    }}
                                    src={image}
                                    alt="blogImage"
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <div className="d-flex">
                              {blogData?.imageUrl?.length > 0 &&
                                blogData?.imageUrl?.map((item) => {
                                  return (
                                    <>
                                      {item?.type == "image" ? (
                                        <div style={{position: "relative"}}>
                                          <img
                                            style={{borderRadius: "12px"}}
                                            className="mx-2"
                                            src={item.url}
                                            width="150px"
                                            height="120px"
                                            onClick={(e) =>
                                              ShowModalPreview(item)
                                            }
                                            alt="blogImage"
                                          />
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="false"
                                            id="imageDeleteIcon"
                                            onClick={(e) =>
                                              handleDeleteBlogImage(e, item)
                                            }
                                          ></i>
                                        </div>
                                      ) : (
                                        <div className="position-relative mx-2">
                                          <div className="playIcon">
                                            <i className="fa fa-play "></i>
                                          </div>
                                          <video
                                            onClick={(e) =>
                                              ShowModalPreview(item)
                                            }
                                            style={{
                                              width: "150px",
                                              height: "120px",
                                              objectFit: "cover",
                                              borderRadius: "12px",
                                              cursor: "pointer",
                                            }}
                                            controls={false}
                                            autoPlay={false}
                                          >
                                            <source
                                              src={item.url}
                                              type="video/mp4"
                                            />
                                          </video>
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="false"
                                            id="imageDeleteIcon12"
                                            onClick={(e) =>
                                              handleDeleteBlogImage(e, item)
                                            }
                                          ></i>
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mt-3 mb-1">
                        <div className="col-md-12">
                        </div>
                      </div>
                      <div>
                        <Editor
                          setBlogData={setBlogData}
                          blogData={blogData}
                          setShowError={setShowError}
                          showError={showError}
                        />
                        {showError.description ? (
                          <p className="text-danger mt-3">
                            {showError.description}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="text-right mt-5 ">
                        <button
                          className="fun-donate-btn1 mx-3"
                          onClick={handleClear}
                        >
                          Clear
                        </button>

                        {isEditPage ? (
                          <>
                            {loader ? (
                              <button className="btn__primary1 buttonPrimary1">
                                <i
                                  className="fa mx-3 fa-circle-o-notch fa-spin"
                                  style={{fontSize: "24px"}}
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn__primary1 buttonPrimary1"
                                onClick={handleSubmit}
                              >
                                Update Blog{" "}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {loader ? (
                              <button className="btn__primary1 buttonPrimary1">
                                <i
                                  className="fa mx-3 fa-circle-o-notch fa-spin"
                                  style={{fontSize: "24px"}}
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn__primary1 buttonPrimary1"
                                onClick={handleSubmit}
                              >
                                Add Blog{" "}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>

        <ToastContainer />
      </div>
    </div>
  );
}
