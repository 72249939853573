import axios from "axios";

export const logout = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admin/logout`,
    payload
  );
};

export const login = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
    payload
  );
};
