import React, {useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import eyeIcon from "../assets/img/Hide.png";
import hideEyeIcon from "../assets/img/Hide-2.png";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../src/assets/img/Logo-04-1.svg";
import {forgotPassword} from "../Api-Calls/forgotPassword";
import {login} from "../Api-Calls/authenticate";
import {useForm} from "react-hook-form";
import TextInput from "../component/InputFields";
import Slider from "../component/Slider";
import Cookies from "js-cookie";
import {SwalErrorMessage, SwalMessage} from "../component/sweetAlertMessage";
const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: {errors},
  } = useForm();

  const showEmailModal = () => {
    setEmailModal(true);
  };
  const handleClose = () => {
    setEmailModal(false);
  };
  const [emailModal, setEmailModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const onSubmit = async (data) => {
    if (data?.email !== "" && data?.password !== "") {
      const res = await login(data);
      try {
        if (res?.status === 200) {
          localStorage.setItem(
            "token",
            JSON.stringify(res?.data?.result?.token)
          );
          localStorage.setItem("user", JSON.stringify(res?.data?.result?.user));
          Cookies.set("token", JSON.stringify(res?.data?.result?.token), {
            // expires: new Date(Date.now() + 604800 * 1000),
            // expires: new Date(Date.now() + 1 * 60 * 1000),
            expires: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)
          });
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          navigate("/dashboard");
          reset({
            email: "",
            password: "",
          });
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    }
  };

  const handleForgotPassword = async (data) => {
    let {forgetEmail} = data;
    let detail = {
      email: forgetEmail,
    };
    setLoader(true);
    const res = await forgotPassword(detail);
    try {
      if (res?.status === 200) {
        SwalMessage(res?.datau.status, res?.data?.message, "success");
        setLoader(false);
        handleClose();
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  return (
    <>
      <section className="d-flex">
        <Slider />

        <div className="form--section">
          <div className="vh-100  bg-white  w-75 mx-auto">
            <Form
              className=" h-100 d-flex justify-content-center align-items-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mx-auto app-login-box col-sm-12 ">
                <div className="px-2">
                  <img src={Logo} className="app-logo" loading="lazy"/>
                  <h2>Welcome back,</h2>
                  <p>Please sign in to your account.</p>
                  <hr />
                </div>
                <Row className="">
                  <Col lg="6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Email address</label>
                      <TextInput
                        name={"email"}
                        className="form-control"
                        rules={{
                          required: "Email is required*",
                          pattern: {
                            value:
                              /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                            message: "Please Enter Valid Email Address",
                          },
                        }}
                        control={control}
                        placeholder="Enter Email Address"
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group ">
                      <label for="exampleInputEmail1">Email password</label>
                      <div className="position-relative">
                        <TextInput
                          type={`${showPassword ? "text" : "password"}`}
                          {...register("password")}
                          className={`form-control `}
                          control={control}
                          showIcon={eyeIcon}
                          icon="true"
                          hideIcon={hideEyeIcon}
                          placeholder="Enter password"
                          setShowPassword={setShowPassword}
                          showPassword={showPassword}
                          rules={{
                            required: "Password is required*",
                            minLength: {
                              value: 8,
                              message:
                                "Password must be more than 8 characters",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-check d-flex align-items-center justify-content-end">
                      <div
                        onClick={showEmailModal}
                        className="forgotPasswordAuth"
                      >
                        Forgot Password?
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />
                <div className="text-right">
                  <button className="btn__primary1 buttonPrimary1" type="submit">
                    Login to Dashboard
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>

      <Modal show={emailModal} id={"forgotPasswod"} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Forgot Password</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Form onSubmit={handleSubmit(handleForgotPassword)}>
          <Modal.Body>
            <div className="p-2">
              Please enter your email, the reset link send to you email address!
              <div className="form-group">
                <label className="col-form-label mb-0">
                  <strong>Email</strong>
                </label>
                <TextInput
                  name={"forgetEmail"}
                  className="form-control"
                  rules={{
                    required: "Email is required*",
                    pattern: {
                      value:
                        /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                      message: "Please Enter Valid Email Address",
                    },
                  }}
                  control={control}
                  placeholder="Enter Email Address"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="fun-donate-btn1 mx-3" onClick={handleClose}>
              Close
            </Button>

            {loader ? (
              <Button variant="primary">
                <i
                  className="fa mx-3 fa-circle-o-notch fa-spin"
                  style={{fontSize: "24px"}}
                ></i>
              </Button>
            ) : (
              <Button  className="btn__primary1 buttonPrimary1" type="submit">
                Reset
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Login;
