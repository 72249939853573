import React, { useState } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteContact } from "../Api-Calls/contactUs";
import moment from "moment";

const Datatable = (props) => {
  const { contactData, setContactData, columns } = props;
  const [tagModal, setTagModal] = useState(false);
  const [contactDetail, setContactDetail] = useState();
  const showTagModal = (e, detail) => {
    setContactDetail(detail);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };

  const deleteContactUs = (e, id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this user.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteContact(id);
          let data = contactData?.filter((item) => item?._id !== id);
          setContactData(data);
          swal("Poof! Your contact has been deleted!", {
            icon: "success",
          });
          toast.success("Contact Deleted Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } catch (err) {
      toast.error(err, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const data = [];

  for (let i = 0; i < contactData?.length; i++) {
    data.push({
      sr: contactData[i]?.seqId,
      fname: contactData[i]?.fullName,
      email: contactData[i]?.email,
      subject: contactData[i]?.subject,
      phoneNumber: contactData[i]?.phoneNo,
      date: moment(contactData[i]?.created).format("L"),
      message: contactData[i]?.message,
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, contactData[i])}
            className="action-button-view">
            <i className="fa fa-eye"></i>
          </Button>{" "}
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
      <Modal show={tagModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Contact Us Detail</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Full Name:</strong>
              </div>
              <div className="mx-2 flex2">{contactDetail?.fullName}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Email:</strong>
              </div>
              <div className="mx-2 flex2">{contactDetail?.email}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Subject:</strong>
              </div>
              <div className="mx-2 flex2">{contactDetail?.subject}</div>
            </div>

            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Phone Number:</strong>
              </div>
              <div className="mx-2 flex2">{contactDetail?.phoneNo}</div>
            </div>

            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Message:</strong>
              </div>
              <div className="mx-2 flex2">{contactDetail?.message}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="fun-donate-btn1 mx-3" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Datatable;
