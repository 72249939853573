import React, {useState} from "react";
import "antd/dist/antd.css";
import {Table} from "antd";
import {Button, Modal} from "react-bootstrap";
import swal from "sweetalert";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {deleteContact} from "../Api-Calls/contactUs";
import moment from "moment";

const VolunteerDatatable = (props) => {
  const {volunteerData, setVolunteerData, columns} = props;
  const [tagModal, setTagModal] = useState(false);
  const [volunteerDetail, setVolunteerDetail] = useState();
  const showTagModal = (e, detail) => {
    setVolunteerDetail(detail);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };

  const deleteContactUs = (e, id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this user.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteContact(id);
          let data = volunteerData?.filter((item) => item?._id !== id);
          setVolunteerData(data);
          swal("Poof! Your volunteer has been deleted!", {
            icon: "success",
          });
          toast.success("Volunteer Deleted Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } catch (err) {
      toast.error(err, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const data = [];

  for (let i = 0; i < volunteerData?.length; i++) {
    data.push({
      sr: volunteerData[i]?.seqId,
      fname: volunteerData[i]?.fullName,
      email: volunteerData[i]?.email,
      volunteerType: volunteerData[i]?.volunteerType,
      country: volunteerData[i]?.country,
      city: volunteerData[i]?.city,
      phoneNumber: volunteerData[i]?.phoneNo,
      date: moment(volunteerData[i]?.created).format("L"),
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, volunteerData[i])}
            className="action-button-view"
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
      <Modal show={tagModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Volunteer Detail</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>SeqId:</strong>
              </div>
              <div className="mx-2 flex2">{volunteerDetail?.seqId}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Full Name:</strong>
              </div>
              <div className="mx-2 flex2">{volunteerDetail?.fullName}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Email:</strong>
              </div>
              <div className="mx-2 flex2">{volunteerDetail?.email}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Volunteer Type:</strong>
              </div>
              <div className="mx-2 flex2">{volunteerDetail?.volunteerType}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Country:</strong>
              </div>
              <div className="mx-2 flex2">{volunteerDetail?.country}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>City:</strong>
              </div>
              <div className="mx-2 flex2">{volunteerDetail?.city}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Phone Number:</strong>
              </div>
              <div className="mx-2 flex2">{volunteerDetail?.phoneNo}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="fun-donate-btn1" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default VolunteerDatatable;
