import React, { useEffect, useState } from "react";
import Footer from "./footer";
import Header from "../component/header";
import Sidemenu from "../component/sidemenu";
import Jumbotran from "../component/Jumbotran";
import swal from "sweetalert";
import { getAdminUserList } from "../Api-Calls/users";
import AdminUserDatatable from "../component/AdminUserDatatable";

const AdminUsers = () => {
  const [tagModal, setTagModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const showTagModal = () => {
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const deleteUser = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your user has been deleted!", {
          icon: "success",
        });
      }
    });
  };
  useEffect(() => {
    getUserListData();
  }, []);
  const getUserListData = async () => {
    const res = await getAdminUserList();
    try {
      if (res?.status == 200) {
        setUserList(res?.data?.result?.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="Admin User" desc="admin users" adminusers="adminusers" />
              <div class="page-inner">
                <div class="row">
                  <div class="col-md-12">
                    <AdminUserDatatable
                      columns={columns}
                      userList={userList}
                      setUserList={setUserList}
                      getUserListData={getUserListData}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
