const token = JSON.parse(localStorage.getItem("token"));

export const config = {
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};
export const header = {
  headers: {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
};
