import swal from "sweetalert";

export const SwalMessage = (title, text, icon, button, dangerMode) => {
  swal({
    title: title,
    text: text,
    icon: icon,
    buttons: button,
    dangerMode: dangerMode,
  });
};

export const SwalErrorMessage = (title, text, icon, dangerMode) => {
  swal({
    title: title,
    text: text,
    icon: icon,
    dangerMode: dangerMode,
  });
};
