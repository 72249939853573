import React, {useEffect, useState} from "react";
import Footer from "./footer";
import Header from "../component/header";
import Sidemenu from "../component/sidemenu";
import Jumbotran from "../component/Jumbotran";
import swal from "sweetalert";
import UserDatatable from "../component/UserDatatable";
import {getUserList} from "../Api-Calls/users";

const Users = () => {
  const [tagModal, setTagModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const showTagModal = () => {
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const deleteUser = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your user has been deleted!", {
          icon: "success",
        });
      }
    });
  };
  useEffect(() => {
    getUserListData();
  }, []);
  const getUserListData = async () => {
    const res = await getUserList();
    try {
      if (res?.status == 200) {
        setUserList(res?.data?.result?.user);
        setFilteredList(res?.data?.result?.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Username",
      dataIndex: "username",
    },

    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Is_Donor",
      dataIndex: "is_donor",
    },
    {
      title: "Is_Fundraiser",
      dataIndex: "is_fundraiser",
    },
    {
      title: "Verified",
      dataIndex: "verified",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const handleFilter = (e) => {
    const value = e.target.value;
    if (value != "-1") {
      const filteredData = userList?.filter((item) => {
        const statusMatch = value === "-1" || item.status === value;
        const verified =
          value === "verified" &&
          item.is_fundraiser === true &&
          item.email_confirmation === "Approved";
        const nonVerified =
          value === "non_verified" &&
          item.is_fundraiser === false &&
          item.email_confirmation === "pending";
        const donors = value === "donors" && item.is_donor === true;
        const fundraiser =
          value === "fundraisers" && item.is_fundraiser === true;

        return statusMatch || verified || nonVerified || donors || fundraiser;
      });
      setFilteredList(filteredData);
    } else {
      getUserList();
    }
  };
  const handleResetFilters = () => {
    setFilteredList(userList);
  };
  const handleSearch = async (e) => {
    let value = e.target.value.toLowerCase();
    if (value.length >= 3) {
      const filteredData = userList?.filter((item) => {
        return (
          item.username.toLowerCase().includes(value) ||
          item.seqId.includes(value.toUpperCase())
        );
      });
      setFilteredList(filteredData);
    } else {
      // If the input has less than 3 characters, reset to the original data
      setFilteredList(userList);
    }
  };
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="Users" desc="users" user="user" />
              <div class="page-inner">
                <div class="row">
                  <div className="ml-3 mb-3 d-flex w-100 justify-content-between align-items-center">
                    <div class="input-group w-25">
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e)}
                        aria-label="Search Something..."
                        aria-describedby="basic-addon2"
                      />
                      <span class="input-group-text border-0" id="search-addon">
                        <i class="fa fa-search"></i>
                      </span>
                    </div>

                    <div className="mr-3 d-flex">
                      <button
                        className="action-button-view  mr-2"
                        onClick={handleResetFilters}
                      >
                        reset
                      </button>
                      <select
                        className="form-control "
                        style={{width: "200px"}}
                        onChange={(e) => handleFilter(e)}
                      >
                        <option value="-1">Select Status</option>
                        <option value="Pending">Pending Profile</option>
                        <option value="Verified">Verified Profile</option>
                        <option value="Reject">Reject Profile</option>
                        <option value="verified">Verified User</option>
                        <option value="non_verified">Non Verified User</option>
                        <option value="donors">Donors</option>
                        <option value="fundraisers">Fundraisers</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <UserDatatable
                      columns={columns}
                      userList={filteredList}
                      setUserList={setUserList}
                      getUserListData={getUserListData}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
