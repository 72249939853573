import React from "react";
import userPicture from "../assets/img/userDefaultImg.png";

const ProfileCard = (props) => {
  const profile = props;
  return (
    <>
      <div className="card card-profile">
        <div
          className="card-header"
          style={{
            backgroundImage: `url(http://demo.themekita.com/atlantis/livepreview/examples/assets/img/blogpost.jpg)`,
            height: `320px`,
          }}
        >
          <div className="profile-picture">
            <div className="avatar avatar-xl">
              {profile?.profile && (
                <img
                  src={
                    profile?.profile?.picture
                      ? profile?.profile?.picture
                      : userPicture
                  }
                  alt="..."
                  className="avatar-img rounded-circle"
                />
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="user-profile text-center">
            <div className="name">{profile?.profile?.username}</div>
            <div className="job">{profile?.profile?.role}</div>
            <div className="desc">{profile?.profile?.aboutMe}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
