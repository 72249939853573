import React, {useEffect, useState} from "react";
import Header from "../component/header";
import Sidemenu from "../component/sidemenu";
import Jumbotran from "../component/Jumbotran";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Footer from "../pages/footer";
import {
  addCategory,
  deleteCategory,
  getCategory,
  updateCategory,
} from "../Api-Calls/category";
import {SwalErrorMessage, SwalMessage} from "./sweetAlertMessage";

const Category = () => {
  const [newCategoryTitle, setNewCategoryTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [category, setCategory] = useState();
  const [showMessage, setShowMessage] = useState("");
  const [editCategory, setEditCategory] = useState(false);

  const handleSubmit = async () => {
    if (newCategoryTitle == "") {
      setShowMessage("Please Enter category name");
    } else {
      try {
        const res = await addCategory({title: newCategoryTitle});
        if (res?.status == 201) {
          setCategory([...category, res?.data?.result]);
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          getCategoryData();
          setNewCategoryTitle("");
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    }
  };
  const handleEditCategory = async (category) => {
    setEditCategory(true);
    setCategoryId(category?._id);
    setNewCategoryTitle(category?.title);
  };
  const handleChange = (e) => {
    setNewCategoryTitle(e.target.value);
  };
  const UpdateCategory = async (e) => {
    if (newCategoryTitle == "") {
      setShowMessage("Please Enter category name");
    } else {
      try {
        const res = await updateCategory(categoryId, {
          title: newCategoryTitle,
        });
        if (res?.status == 200) {
          setCategory([...category, res?.data?.result]);
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          getCategoryData();
          setEditCategory(false);
          setNewCategoryTitle("");
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    }
  };
  const handleDeleteCategory = (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this category.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteCategory(id);
          let data = category?.filter((item) => item?._id !== id);
          setCategory(data);
          swal("Poof! Your selected category has been deleted!", {
            icon: "success",
          });
        }
      });
      getCategoryData();
      setNewCategoryTitle("");
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const getCategoryData = async () => {
    try {
      const res = await getCategory();
      if (res?.status == 200) {
        setCategory(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClear = () => {
    setNewCategoryTitle("");
    setEditCategory(false);
  };
  useEffect(() => {
    getCategoryData();
  }, []);
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="Category" desc="category" category="category" />
              <div className="page-inner">
                <div className="row">
                  <div className="col-md-6 mx-auto mt-5">
                    <div class="form-group form-group-default">
                      <label>Category Title</label>
                      <input
                        type="text"
                        class="form-control"
                        name="title"
                        value={newCategoryTitle}
                        placeholder="Enter Category Title"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="text-right mt-3 mb-3">
                      <button
                        className="fun-donate-btn1 mx-2"
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                      {editCategory ? (
                        <button
                          className="btn__primary1 buttonPrimary1"
                          onClick={(e) => UpdateCategory(e)}
                        >
                          Edit Category
                        </button>
                      ) : (
                        <button
                          className="btn__primary1 buttonPrimary1"
                          onClick={() => handleSubmit()}
                        >
                          Add Category
                        </button>
                      )}
                    </div>

                    <div className="p-3 shadow my-5 ">
                      {category?.map((category, index) => {
                        return (
                          <>
                            <div className="d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">{category?.title}</h6>
                              <div>
                                <span
                                  className="mx-3"
                                  style={{cursor: "pointer"}}
                                  onClick={() => {
                                    handleEditCategory(category);
                                  }}
                                >
                                  <i className="fa fa-edit text-success"></i>
                                </span>
                                <span
                                  style={{cursor: "pointer"}}
                                  onClick={() =>
                                    handleDeleteCategory(category?._id)
                                  }
                                >
                                  <i className="fa fa-trash text-danger"></i>
                                </span>
                              </div>
                            </div>
                            <hr className="my-2" />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default Category;
