import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getSupporterById} from "../Api-Calls/supporter";
import Header from "../component/header";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Jumbotran from "../component/Jumbotran";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";
import TransactionDatatable from "../component/TransectionDatatable";
const Transaction = (props) => {
  const {id} = useParams();
  const [donationDetails, setDonationDetails] = useState([]);

  useEffect(() => {
    if (donationDetails) handleDonations();
  }, []);
  const handleDonations = async () => {
    const res = await getSupporterById(id);
    try {
      if (res?.status === 200) {
        setDonationDetails(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Fundraise Name",
      dataIndex: "title",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
    },
    {
      title: "Donation Amount",
      dataIndex: "donationAmount",
    },
    {
      title: "Platform Fee",
      dataIndex: "platformFee",
    },
    {
      title: "Paymob Fee",
      dataIndex: "paymobFee",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran
              page="All Transaction"
              desc="Transaction"
              transaction="transaction"
            />
            <div className="page-inner">
              <h1 style={{color: "#002866"}}>
                <strong>{localStorage.getItem("fundraiseTitle")}</strong>
              </h1>
              <TransactionDatatable
                columns={columns}
                donationDetails={donationDetails}
                setDonationDetails={setDonationDetails}
                id={id}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Transaction;
