import { DonationIconTwo } from "../../data/Assets";

export default function AmountCard({amount}) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center ">
                    <img className="cardIcon" src={DonationIconTwo} alt="Donation" />
                    <div className="ml-3">
                        {/* <h3 className="cardTitle">{amount ? amount.toLocaleString() + " " + "Rs" : "0 Rs"}</h3> */}
                        <h3 className="cardTitle">{amount}</h3>
                        {/* <p>Amount Spent</p> */}
                        <p>Created Fundraiser</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
