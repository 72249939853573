import React, {useEffect, useState} from "react";
import SubscriberDatatable from "../component/SubscriberDatatable";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import Sidemenu from "../component/sidemenu";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer";
import {getAllSubscriber} from "../Api-Calls/subscribe";
const Subscriber = () => {
  const [subscriberData, setSubscriberData] = useState();

  useEffect(() => {
    getSubscriber();
  }, []);
  const getSubscriber = async () => {
    try {
      const res = await getAllSubscriber();
      if (res?.status == 200) {
        const subscriberList = res?.data?.result;
        const reversedSubscriberList = subscriberList
          ? [...subscriberList].reverse()
          : [];
        setSubscriberData(reversedSubscriberList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Subscribed",
      dataIndex: "subscriber",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran
              page="Subscriber"
              desc="subscriber"
              subscriber="subscriber"
            />
            <div key={"data"} className="page-inner">
              <SubscriberDatatable
                columns={columns}
                subscriberData={subscriberData}
                setSubscriberData={setSubscriberData}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Subscriber;
