import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import Header from "../component/header";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";

const AddUser = (props) => {
  const [tagModal, setTagModal] = useState(false);
  const showTagModal = () => {
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <div className="page-inner">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-with-nav">
                    <div className="card-body">
                      <div className="row mt-3">
                        <div className="col-md-12 mb-3">
                          <div className="form-group form-group-default">
                            <label>Username</label>
                            <input
                              type="username"
                              className="form-control"
                              name="username"
                              placeholder="Enter Username"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="fname"
                              placeholder="Enter First Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group form-group-default">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="lname"
                              placeholder="Enter Last Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group form-group-default">
                            <label>Email Address</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Enter Email Address"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-group-default">
                            <label>Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              placeholder="Enter Password"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-group-default">
                            <label>User Role</label>
                            <select className="form-control">
                              <option>Select User Role</option>
                              <option>User</option>
                              <option>Admin</option>
                              <option>Super Admin</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="text-right mt-3 mb-3">
                        <button className="btn btn-danger mx-3">Cancel</button>
                        <button
                          className={
                            props.page ? "btn btn-success" : "btn btn-primary"
                          }
                        >
                          {props.page ? "Update User" : "Add User"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        <Modal show={tagModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Add Tag</Modal.Title>
            <button onClick={handleClose} className="border-0">
              X
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <label className="mb-2">Add New Tag</label>
              <input
                type={"text"}
                className="form-control"
                placeholder="Enter keyword"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Add Tag
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AddUser;
