import React from "react";
import {Link} from "react-router-dom";

const Jumbotran = (props) => {
  return (
    <div className="panel-header bg-primary-gradient">
      <div className="page-inner py-5">
        <div className="d-flex align-items-center flex-column flex-md-row">
          <div>
            <h2 className="text-white pb-2 fw-bold">{props?.page}</h2>
            <h5
              className="text-white op-7 mb-2"
              style={{color: "#fff !important", opacity: "1"}}
            >
              <Link className="text-decoration-none" to={"/dashboard"}>
                {" "}
                Dashboard{" "}
              </Link>{" "}
              {props?.desc && <i className="fa fa-angle-right "></i>}{" "}
              <span style={{opacity: ".57"}}>{props?.desc}</span>
            </h5>
          </div>
          {!props?.user &&
            !props?.adminusers &&
            !props?.category &&
            !props?.supporter &&
            !props?.contactus &&
            !props.transaction &&
            !props?.subscriber &&
            !props?.payouts &&
            !props.userFundraiser &&
            !props.campaignStories &&
            !props.userDonations && 
            !props.completedFundraiser && 
            !props.volunteer && (
              <>
                {/* <div className="ml-md-auto py-2 py-md-0"> */}
                <div className="ml-md-auto py-2 py-md-0 button-responsive">
                  <Link to={"/Fundraiser"} className="fun-donate-btn2 mx-2">
                    Manage Fundraiser
                  </Link>
                  <Link to="/add-fundraise" className="fun-donate-btn2 mx-2 mt-3">
                    Add Fundraiser
                  </Link>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default Jumbotran;
