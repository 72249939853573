import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="d-flex justify-content-between align-items-center">
        <nav className="pull-left">
          <ul className="nav">
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://buildingbridgesforislam.com/privacy-policy"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright text-right">
          © All right reserved 2023{" "}
          <strong className="text-primary">buildingbridgesforislam</strong>{" "}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
