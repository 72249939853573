import React, { useState } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import Loader from "../Loader";
import swal from "sweetalert";
import HTMLRenderer from "react-html-renderer";
import { deleteBlog } from "../../Api-Calls/blogs";
import { SwalErrorMessage } from "../sweetAlertMessage";
import moment from "moment";
const BlogListTable = (props) => {
  const { blogs, setBlogs, columns } = props;
  const [blogDetails, setBlogsDetails] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const showTagModal = (details) => {
    setBlogsDetails(details);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const navigate = useNavigate();
  const handleDeleteBlog = (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this blog.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteBlog(id);
          let filteredData = blogs?.filter((item) => item?._id !== id);
          setBlogs(filteredData);
          swal("Poof! Your selected blog has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const handleEditBlog = (blog) => {
    navigate(`/blog-form`, { state: { ...blog } });
  };
  const data = [];
  for (let i = 0; i < blogs?.length; i++) {
    data.push({
      sr: blogs[i]?.seqId,
      title: blogs[i]?.title,
      description: blogs[i]?.description,
      date: moment(blogs[i]?.created).format("L"),
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(blogs[i])}
            className="action-button-view">
            <i className="fa fa-eye"></i>
          </Button>{" "}
          &nbsp;
          <Button
            onClick={(e) => handleEditBlog(blogs[i])}
            className="action-button-edit">
            <i className="fa fa-edit"></i>
          </Button>{" "}
          &nbsp;
          <Button
            onClick={(e) => handleDeleteBlog(blogs[i]?._id)}
            className="action-button-delete">
            <i className="fa fa-trash"></i>
          </Button>
        </>
      ),
    });
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [loading, setLoading] = useState(false);
  const ShowModalPreview = () => {
    var el = document.getElementById("full-screenVideo");
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Blog Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Title:</strong>
                  </div>
                  <div className="mx-2 flex2">{blogDetails?.title}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Attachments:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    {blogDetails?.attachments?.length > 0 &&
                      blogDetails?.attachments?.map((item) => {
                        return (
                          <>
                              <div style={{ position: "relative" }}>
                                <img
                                  src={item?.url}
                                  id="full-screenVideo"
                                  onClick={ShowModalPreview}
                                  width="150px"
                                  height="120px"
                                  style={{ objectFit: "cover" }}
                                  className="mx-1 mt-3 mb-4"
                                />
                              </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Description:</strong>
                  </div>
                  <div
                    className="mx-2 flex2 mb-3"
                    style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <HTMLRenderer html={blogDetails?.description} />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="fun-donate-btn1" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default BlogListTable;
