import React, {useEffect, useState} from "react";
import Footer from "./footer";
import Header from "../component/header";
import Sidemenu from "../component/sidemenu";
import Jumbotran from "../component/Jumbotran";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {getFundraise} from "../Api-Calls/fundraiser";
import FundraiseDatatable from "../component/FundraiseDatatable";

const Fundraiser = () => {
  const [fundraiser, setFundraiser] = useState([]);
  const [filteredFundraiserList, setFilteredFundraiserList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    getFundraiseData();
  }, []);

  const getFundraiseData = async () => {
    const res = await getFundraise();
    try {
      if (res?.status == 200) {
        const fundraiserList = res?.data?.result;
        setFundraiser(fundraiserList);
        setFilteredList(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Description",
      dataIndex: "description",
    },

    {
      title: "Collected Amount",
      dataIndex: "collectedAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Transactions",
      dataIndex: "transaction",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  const handleFilter = (e) => {
    const value = e.target.value;
    if (value !== "-1") {
      const filteredData = fundraiser?.filter((item) => {
        return value === "-1" || item.status === value;
      });
      setFilteredList(filteredData);
    } else {
      setFilteredList(fundraiser);
    }
  };

  const handleResetFilters = () => {
    setFilteredList(fundraiser);
  };

  const handleSearch = async (e) => {
    let value = e.target.value.toLowerCase();
    if (value.length >= 3) {
      const filteredData = fundraiser?.filter((item) => {
        return (
          item.category[0].slug.includes(value) ||
          item.seqId.includes(value.toUpperCase()) ||
          item.title.toLowerCase().includes(value)
        );
      });
      setFilteredList(filteredData);
    } else {
      // If the input has less than 3 characters, reset to the original data
      setFilteredList(fundraiser);
    }
  };
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="Fundraiser" desc="fundraiser" />
              <div className="page-inner">
                <div className="row">
                  <div className="ml-3 mb-3 d-flex w-100 justify-content-between align-items-center">
                    <div class="input-group w-25">
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e)}
                        aria-label="Search Something..."
                        aria-describedby="basic-addon2"
                      />
                      <span class="input-group-text border-0" id="search-addon">
                        <i class="fa fa-search"></i>
                      </span>
                    </div>

                    <div className="mr-3 d-flex">
                      <button
                        className="action-button-view  mr-2"
                        onClick={handleResetFilters}
                      >
                        reset
                      </button>
                      <select
                        className="form-control "
                        style={{width: "200px"}}
                        onChange={(e) => handleFilter(e)}
                      >
                        <option value="-1">Select Status</option>
                        <option value="Active">Active Fundraisers</option>
                        <option value="Pending">Pending Fundraisers</option>
                        <option value="Complete">Complete Fundraisers</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <FundraiseDatatable
                      columns={columns}
                      fundraiser={filteredList}
                      setFundraiser={setFundraiser}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default Fundraiser;
