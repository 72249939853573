import axios from "axios";
import { config } from "./axiosHeader";

export const addBlog = async (formData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/blog/add-blog`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateBlog = async (id, formData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/blog/update-blog/${id}`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getBlogs = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/get-blogs`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteBlog = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/blog/delete-blog/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteBLogImage = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/blog/delete-image/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};