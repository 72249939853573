import React, {useState} from "react";
import eyeIcon from "../assets/img/Hide.png";
import hideEyeIcon from "../assets/img/Hide-2.png";
import Footer from "./footer";
import {useNavigate} from "react-router";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {changePassword} from "../Api-Calls/forgotPassword";
import {useParams} from "react-router";
import {SwalErrorMessage, SwalMessage} from "../component/sweetAlertMessage";
const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const {user_id, token} = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleUpdatePassword = () => async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    form.classList.add("was-validated");
    if (confirmPassword !== newPassword) {
      SwalErrorMessage("Oops!", "Passwords does not match", "error", true);
      return;
    }
    const details = {
      newPassword,
      user_id,
      token,
    };
    const res = await changePassword(details);
    try {
      if (res?.status == 200) {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <div className="main-panel w-100">
            <div className="container">
              <div className="page-inner">
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <div className=" card-with-nav">
                      <div className="my-5">
                        <h1 className="text-center text-primary">
                          Forgot Password form
                        </h1>
                      </div>
                      <div className="card-body">
                        <div className="row mt-4">
                          <div className="col-md-8 mx-auto">
                            <div className="form-group form-group-default position-relative">
                              <label>New Password</label>
                              <input
                                type={`${
                                  showNewPassword ? "text" : "password"
                                }`}
                                className="form-control"
                                value={newPassword}
                                id="newPassword"
                                minLength="8"
                                autoComplete={false}
                                name="current_password"
                                placeholder="Please enter your  new Password here"
                                onChange={(e) => {
                                  setNewPassword(e.target.value);
                                }}
                                required
                              />
                              <div
                                className="input-group-append align-items-end"
                                style={{
                                  position: "absolute",
                                  top: "55%",
                                  right: "-10px",
                                  transform: "translate(-50%, -23%)",
                                }}
                              >
                                <button
                                  className="btn btn-sm btn-link pb-0"
                                  type="button"
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                >
                                  <img
                                    width="18"
                                    src={
                                      !showNewPassword ? eyeIcon : hideEyeIcon
                                    }
                                    alt="icon"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-8 mx-auto">
                            <div className="form-group form-group-default position-relative">
                              <label>Confirm Password</label>
                              <input
                                type={`${
                                  showConfirmPassword ? "text" : "password"
                                }`}
                                id="confirmPassword"
                                className="form-control"
                                name="current_password"
                                value={confirmPassword}
                                autoComplete={false}
                                minLength="8"
                                placeholder="Please enter your confirm Password here"
                                onChange={(e) => {
                                  setConfirmPassword(e.target.value);
                                }}
                                required
                              />
                              <div
                                className="input-group-append align-items-end"
                                style={{
                                  position: "absolute",
                                  top: "55%",
                                  right: "-10px",
                                  transform: "translate(-50%, -23%)",
                                }}
                              >
                                <button
                                  className="btn btn-sm btn-link pb-0"
                                  type="button"
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                >
                                  <img
                                    width="18"
                                    src={
                                      !showConfirmPassword
                                        ? eyeIcon
                                        : hideEyeIcon
                                    }
                                    alt="icon"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-right col-8 mx-auto mt-4 mb-4">
                          <button className="btn btn-info mx-3">Cancel</button>

                          <button
                            className="btn btn-primary"
                            onClick={handleUpdatePassword()}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
