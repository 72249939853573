import React, { useEffect, useState } from "react";
import VolunteerDatatable from "../component/VolunteerDatatable";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import Sidemenu from "../component/sidemenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer";
import { getVolunteerData } from "../Api-Calls/contactUs";
const Volunteer = () => {
  const [volunteerData, setVolunteerData] = useState([]);
  useEffect(() => {
    getVolunteers();
  }, []);
  const getVolunteers = async () => {
    try {
      const res = await getVolunteerData();
      if (res?.status == 200) {
        const volunteerList = res?.data?.result;
        const reversedVolunteerList = volunteerList
          ? [...volunteerList].reverse()
          : [];
        setVolunteerData(reversedVolunteerList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
     {
      title: "Sr",
      dataIndex: "sr",
    },
    {
      title: "Full Name",
      dataIndex: "fname",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Volunteer Type",
      dataIndex: "volunteerType",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran
              page="Volunteer"
              desc="volunteer"
              volunteer="volunteer"
            />
            <div key={"data"} className="page-inner">
              <VolunteerDatatable
                columns={columns}
                volunteerData={volunteerData}
                setVolunteerData={setVolunteerData}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Volunteer;
