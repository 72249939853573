import axios from "axios";
import { config, header } from "./axiosHeader";

export const getFundraise = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/fundraiser/get-all-admin-fundraiser`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getTopFundraiser = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/fundraiser/get-top-fundraisers`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getCompletedFundrasier = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/fundraiser/get-completed-fundraisers`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updateFundraiserWithdrawStatus = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/update-fundraiser-withdraw-status/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};


export const addFundraise = async (formData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/add-admin-fundraiser`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteFundraiser = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/delete-admin-fundraiser/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteImage = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/delete-admin-fundraiser-image/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deleteDocument = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/funraiser/delete-admin-fundraiser-document/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deleteFundrasierVideo = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/delete-admin-fundraiser-video/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const getFundraiseById = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/get-fundraiser-by-id/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateFundraise = async (id, formData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/update-admin-fundraiser/${id}`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
