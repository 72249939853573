import React, {useState} from "react";
import "antd/dist/antd.css";
import {Table} from "antd";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Button, Modal} from "react-bootstrap";
import {deleteAdminUser, updateSupporterUser} from "../Api-Calls/users";
import swal from "sweetalert";
import moment from "moment";
import {SwalErrorMessage, SwalMessage} from "./sweetAlertMessage";
import Loader from "./Loader";
import userPicture from "../assets/img/userDefaultImg.png";

const AdminUserDatatable = (props) => {
  const {userList, columns, getUserListData} = props;
  const [userDetail, setUserDetail] = useState();
  const [userData, setUserData] = useState();
  const [tagModal, setTagModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const showTagModal = (e, details) => {
    setUserDetail(details);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const handleDeleteUser = (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this user.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setUserLoading(true);
          deleteAdminUser(id, willDelete)
            .then((res) => {
              SwalMessage(res?.data?.status, res?.data?.message, "success");
              setUserLoading(false);
              getUserListData();
            })
            .catch((error) => {
              SwalErrorMessage(
                "Oops!" + " " + error?.response?.data?.status,
                error?.response?.data?.message,
                "error",
                true
              );
            });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const data = [];
  for (let i = 0; i < userList?.length; i++) {
    data.push({
      sr: userList[i]?.seqId,
      username: userList[i]?.username,
      email: userList[i]?.email,
      date: moment(userList[i]?.updated).format("L"),
      role: userList[i]?.role ? userList[i]?.role : "User",
      status: (
        <span
          className={`${
            userList[i]?.status === "Pending"
              ? "btn btn-sm btn-outline-warning font-weight-bold"
              : "btn btn-sm btn-outline-success font-weight-bold"
          }`}
        >
          {userList[i]?.status}
        </span>
      ),
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, userList[i])}
            className="action-button-view"
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
          &nbsp;
          <Button
            onClick={(e) => handleDeleteUser(userList[i]?._id)}
            className="action-button-delete"
          >
            <i className="fa fa-trash"></i>
          </Button>
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const handleChange = (e) => {
    let {name, value} = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const handleUpdate = async () => {
    try {
      setLoading(true);
      const res = await updateSupporterUser(userDetail?._id, userData);
      if (res?.status) {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        getUserListData();
        handleClose();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
      setLoading(false);
    }
  };
  return (
    <>
      {userLoading ? (
        <Loader />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>User Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>First Name:</strong>
                  </div>
                  <div className="mx-2 flex2 text-capitalize">
                    {userDetail?.first_name}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Last Name:</strong>
                  </div>
                  <div className="mx-2 flex2 text-capitalize">
                    {userDetail?.last_name}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>User Name:</strong>
                  </div>
                  <div className="mx-2 flex2 text-capitalize">
                    {userDetail?.username}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>SeqId:</strong>
                  </div>
                  <div className="mx-2 flex2 text-capitalize">
                    {userDetail?.seqId}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Email:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.email}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Profile Picture:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    <div style={{position: "relative"}}>
                      <img
                        src={
                          userDetail?.picture != ""
                            ? userDetail?.picture
                            : userPicture
                        }
                        width="100px"
                        height="100px"
                        objectFit="cover"
                        className="mx-1 my-3"
                        alt="profileImage"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Role:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {userDetail?.role ? userDetail?.role : "User"}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Gender:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {userDetail?.gender}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Country:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.country}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Phone No:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.phoneNo}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Address:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.address}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>zipcode:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.zipcode}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Status:</strong>
                  </div>
                  <div className="mx-2 flex2">{userDetail?.status}</div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button  className="fun-donate-btn1 mx-3" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default AdminUserDatatable;
