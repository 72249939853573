import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Editor = ({blogData, setBlogData, setShowError, showError}) => {
  const handleChange = (html) => {
    setBlogData({...blogData, description: html});
    setShowError({
      ...showError,
      description: "",
    });
  };

  return (
    <div>
      <ReactQuill
        onChange={handleChange}
        value={blogData.description}
        modules={Editor.modules}
        formats={Editor.formats}
        bounds=".app"
        placeholder={"Write here..."}
      />
    </div>
  );
};

Editor.modules = {
  toolbar: [
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{font: []}],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
    ["link", "image", "video"],
    // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{align: []}],
    // [
    //   {
    //     color: ["#F00", "#0F0", "#00F", "#000", "#FFF", "color-picker"],
    //   },
    // ],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "code",
];

Editor.propTypes = {
  placeholder: PropTypes.string,
};

export default Editor;
