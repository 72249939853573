import axios from "axios";
import { config, header } from "./axiosHeader";

export const getCampaignStories = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/campaign-story/get-stories`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getCampaignStory = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/campaign-story/get-story-by-slug/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updateCampaignStory = async (id, formData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/campaign-story/update-story/${id}`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};