import React, {useEffect, useState} from "react";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";
import PayoutDatatable from "../component/PayoutDatatable ";
import {getCompletedFundrasier} from "../Api-Calls/fundraiser";
const Payouts = () => {
  const [completeFundraiser, setCompleteFundraiser] = useState([]);
  useEffect(() => {
    getFundraiserData();
  }, []);
  const getFundraiserData = async () => {
    const res = await getCompletedFundrasier();
    try {
      if (res?.status == 200) {
        setCompleteFundraiser(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: "Fundraiser Id",
      dataIndex: "fundraiserId",
    },
    {
      title: " Username",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Fundraiser Title",
      dataIndex: "title",
    },
    {
      title: "Fundraiser Status",
      dataIndex: "fundraiser_status",
    },
    {
      title: "Withdraw Status",
      dataIndex: "Withdraw_status",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: completeFundraiser.length > 0 && "Proof of Payment",
      dataIndex: completeFundraiser.length > 0 && "proof_of_payment",
    },
    {
      title: "Withdraw Amount",
      dataIndex: "Withdraw_Amount",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran page="Payouts" desc="payouts" payouts="payouts" />
            <div className="page-inner">
              <PayoutDatatable
                columns={columns}
                completeFundraiser={completeFundraiser}
                setCompleteFundraiser={setCompleteFundraiser}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Payouts;
